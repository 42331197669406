import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Headline = ({as, align, size, theme, ...props}) => {

  const HeadlineTag = as;

  const {className, ...rest} = props;

  const classNames = classnames(`text-${align} font-body-extended`, 
    {'text-purple': theme === 'light', 'text-red': theme === 'dark', 'text-white': theme === 'contrast'},
    {'text-center': align === 'center'},
    className
  )

  return (
    <HeadlineTag className={classNames} {...rest}>{props.children}</HeadlineTag>
  )
}

Headline.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5']).isRequired,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  theme: PropTypes.oneOf(['light', 'dark', 'contrast'])
}

Headline.defaultProps = {
  align: 'left',
  theme: 'dark'
}

export default Headline;
