import React, {useState} from 'react';
import classnames from 'classnames';

export default function Input({label, name, filled, onChange, type}) {

  const [hasFocus, setHasFocus] = useState(false);

  const InputTag = type !== 'textarea' ? 'input': 'textarea';

  const isTextarea = () => {
    return type === 'textarea';
  }

  return (
    <div className={classnames('relative')}>
      <label htmlFor={name} className={classnames("transition-all absolute text-red left-2 top-1 text-medium",
          { 'text-[8px]': hasFocus || filled }
        )}>{label}</label>
      <InputTag 
        key={name}
        name={name}
        id={name}
        className={classnames("w-full bg-transparent text-red border-red border text-medium text-xl flex items-center pl-2 rounded-none", 
          {'h-[160px] md:h-[240px] pt-4': isTextarea()},
          {'h-[48px] md:h-[60px]': !isTextarea()}
        )}
        onInput={onChange}
        onFocus={(e) => setHasFocus(true)} 
        onBlur = {(e) => setHasFocus(false)}
      />
    </div>
  )
}


