import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Headline from '../Headline';
import { smoothScroll } from '../../utils/helper';

const CtaBox = ({headline, theme, ctaLink, ctaText, ...props}) => {

  const {className, ...rest} = props;

  const classNames = classnames(
    className,
    'group relative width-full block p-[10px]'
  )

  const constThemeBg = theme === 'light' ? 'bg-red': 'bg-purple';
  
  const classNamesInner = classnames(constThemeBg,
    {'text-purple': theme === 'light', 'text-red': theme === 'dark'},
    'min-h-[220px] py-10 px-[30px] flex flex-col md:min-h-[470px] md:justify-center'
  )

  const cornerClasses = [
    'top-0 left-0',
    'top-0 right-0',
    'bottom-0 right-0',
    'bottom-0 left-0'
  ];

  return (
    <a href={ctaLink} onClick={(e) => smoothScroll(e, ctaLink)} className={classNames} {...rest}>
      {
        cornerClasses.map((corner, i) => {
          return <div key={i} className={`w-[10px] h-[10px] absolute ${corner} ${constThemeBg}`}></div>
        })
      }
      <div className={classNamesInner}>
        <Headline as="h3" theme={theme} className="mb-3 md:mb-7" dangerouslySetInnerHTML={{ __html: headline}} />
        <div className="mt-auto md:mt-0">
          <span className="font-body-extended uppercase tracking-widest text-xs md:text-base leading-6 border-b group-hover:text-beige-dark transition-colors">{ctaText}</span>
        </div>
      </div>
    </a>
  )
}

CtaBox.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  headline: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
}

CtaBox.defaultProps = {
  theme: 'light'
}


export default CtaBox;


