import React from 'react'
import classnames from 'classnames'

const Copy = ({...props}) => {

  const classNames = classnames(`text-lg md:text-[26px] font-body-extended leading-copy`,
    props.className
  );

  return (
    <p className={classNames}>{props.children}</p>
  )
}

export default Copy;
