import React, {useEffect, createRef, useState} from 'react'
import lottie from 'lottie-web';
import Investors_QualityAsset from '../../animations/Investors_QualityAsset.json';
import Investors_Security from '../../animations/Investors_Security.json';
import Investors_Expertise from '../../animations/Investors_Expertise.json';
import Investors_Simplicity from '../../animations/Investors_Simplicity.json';
import AO_Flexibility from '../../animations/AO_Flexibility.json';
import AO_Liquidity from '../../animations/AO_Liquidity.json';
import AO_Expertise from '../../animations/AO_Expertise.json';
import AO_Simplicity from '../../animations/AO_Simplicity.json';


export default function Logo({benefit,...props}) {
  let benefitContainer = createRef();
  let anim = null;
  const [isPlaying, setIsPlaying] = useState(false);

  const animations = {
    "Investors_QualityAsset": Investors_QualityAsset,
    "Investors_Security": Investors_Security,
    "Investors_Expertise": Investors_Expertise,
    "Investors_Simplicity": Investors_Simplicity,
    "AO_Flexibility": AO_Flexibility,
    "AO_Liquidity": AO_Liquidity,
    "AO_Expertise": AO_Expertise,
    "AO_Simplicity": AO_Simplicity,
  }

  useEffect(() => {

    anim = lottie.loadAnimation({
      container: benefitContainer.current,
      loop: true,
      autoplay: false,
      animationData: animations[benefit.name],
    });

    anim.addEventListener("enterFrame", function (animation) {
      if (animation.currentTime > (anim.totalFrames - 1)) {
        anim.goToAndPlay(1, true);
        setIsPlaying(false);
        anim.pause();
      }
    });
     
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  const play = () => {
    if(!isPlaying) {
      anim.setDirection(1);
      anim && anim.play();
    }
  }
 
  const rewind = () => {
    anim && anim.setDirection(-1);
  }

  return (
    <div onMouseEnter={play} className="benefit">
      <div className="h-[100px] flex justify-center mb-8 md:mb-[70px]">
        <div className='h-[100px] w-[100px]' ref={benefitContainer}></div>
      </div>
      <h5 className="text-lg md:text-xl font-medium md:font-body-extended mb-1">{benefit.headline}</h5>
      <p>{benefit.copy}</p>
    </div>
  )
}


