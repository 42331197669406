import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Legal/Legal.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>Für den erstmaligen Erwerb von Finanzinstrumenten in Form von token-basierten, digitalen Wertpapieren müssen Sie die 360X Art SafeKeeping App installieren. Die App aktiviert die Erstellung eines ‘self-custody’ Wallets für Ihre digitalen Wertpapiere auf der Blockchain. Darüber hinaus wird die App zur Freigabe von Transaktionen Ihrer digitalen Wertpapiere verwendet (ähnlich wie bei einer Push-Tan-App).</p>
    <p>Sie können Ihre digitalen Wertpapiere nur in Ihrem Plattform-Konto einsehen und verwalten, nicht über die App. Die App dient lediglich der Erstellung eines externen Wallets und der Freigabe zukünftiger Transaktionen.</p>
    <p>Bei der App-Einrichtung erhalten Sie eine Wortliste (auch: ‘Seed Phrase’, ‘Mnemonic Phrase’), die ähnlich wie ein Schlüssel zu einem Schließfach funktioniert. Die Wortliste dient der Wiederherstellung Ihrer App und Ihrer digitalen Wertpapiere im Falle von PIN-Verlust, Löschung der App oder Wechsel Ihres Smartphones.</p>
    <h2>Häufig gestellte Fragen</h2>
    <h3>1.	Was ist eine Wortliste?</h3>
    <ol type="a">
  <li>Eine Wortliste ist im traditionellen Sinne eine Lerntechnik, um sich komplexe Inhalte leichter merken und abrufen zu können, ähnlich einer Eselsbrücke. Die Wortliste bietet Ihnen eine einfache und sichere Möglichkeit, Ihre 360X Art SafeKeeping App wiederherzustellen. </li>
  <li>Eine Wortliste ist einfacher zu merken, aufzuschreiben und einzugeben. Eine Wortliste kann wie folgt aussehen:
  <p>witch / collapse / practice / feed / shame / open / despair / creek / road / again / ice / least</p>
  </li>
    </ol>
    <h3>2.	Wie sicher ist eine Wortliste?</h3>
    <ol type="a">
  <li>Die englische Wortliste für den von uns genutzten Standard (BIP-39) enthält 2.048 Wörter. Gehen wir von einer Wortliste von 12 Wörtern aus, so ergeben sich 2<sup>128</sup> Kombinationen (=340.282.366.920.938.463.463 Trillionen).</li>
  <li>Sicherheitsexperten stufen die Wortliste somit als sicher ein.</li>
    </ol>
    <h3>3.	Warum kann ich nicht meine eigene Wortliste entwerfen?</h3>
    <ol type="a">
  <li>Dem menschlichen Gehirn ist es unmöglich, in Zufälligkeiten zu denken. Bei dem Wort ‘Hund’ wird oft an verknüpfte Wörter wie ‘Napf’, ‘Katze’ oder andere Wörter gedacht, die Ähnlichkeiten mit dem erst gewählten Begriff haben. Somit sind Wortlisten, die von Menschen generiert werden, äußerst unsicher.</li>
  <li>Durch die automatische Generierung der Wortliste mittels der 360X Art SafeKeeping App erhöhen Sie das Sicherheitsniveau.</li>
    </ol>
    <h3>4.	Wie kann ich meine Wortliste einsehen?</h3>
    <ol type="a">
  <li><i>Während der App-Einrichtung</i><br />
  Nach erstmaliger Eingabe Ihrer Login-Daten,  im Rahmen der App-Einrichtung, werden Ihnen 12 Wörter angezeigt, die Sie notieren und sicher aufbewahren müssen.
  <ul><li>Die Wörter sind von 1-12 nummeriert. Die <u>vorgegebene Reihenfolge</u> muss bei der Niederschrift unbedingt beachtet werden.</li></ul>
  </li>
  <li><i>Nach der App-Einrichtung</i><br />
  Nach der App-Einrichtung haben Sie die Möglichkeit, Ihre Wortliste jederzeit in Ihrer 360X Art SafeKeeping App einzusehen. Öffnen Sie dazu Ihre App > ‘Menü’ > ‘Wortliste exportieren’, um auf Ihre Wortliste zuzugreifen.
  <ul><li>Sollten Sie die Wortliste nochmals notieren, gilt auch hier wieder, dass die <u>vorgegebene Reihenfolge</u> unbedingt eingehalten werden muss.</li></ul>
  </li>
    </ol>
    <h3>5.	Worauf sollte ich bei der Niederschrift meiner Wortliste achten?</h3>
    <ol type="a">
      <li>Bitte prüfen Sie die Korrektheit Ihrer Wortliste.</li>
      <li>Es ist wichtig, dass Sie Ihre Wortliste <u>sorgfältig und leserlich</u> notieren.</li>
      <li>Beachten Sie die <u>vorgegebene Reihenfolge der 12 Wörter</u>, da diese essentiell für die erfolgreiche Wiederherstellung Ihrer App ist.</li>
      <li>Es ist empfehlenswert, Sicherheitskopien Ihrer Wortliste zu erstellen. </li>
    </ol>
    <h3>6.	Verwahrung der Wortliste</h3>
    <ol type="a">
      <li>Es ist wichtig, die Niederschriften Ihrer Wortliste an sicheren Orten zu verwahren. Wie Geld oder Schmuck spiegelt auch die Wortliste einen Vermögenswert in Form Ihrer digitalen Wertpapiere, die Sie mit der 360X Art Safekeeping App verwalten, wider.</li>
      <li><u>Wichtig</u>: Stellen Sie sicher, dass die Wortliste <u>Dritten nicht zugänglich</u> gemacht wird. 360X Art wird Sie <u>niemals</u> nach Ihrer Wortliste fragen. Geben Sie die Wortliste unter keinen Umständen auf Webseiten an.</li>
    </ol>
    <h4>6.1. Sicherung der Wortliste auf Papier (empfohlen):</h4>
    <ol type="a">
      <li>Benutzen Sie <u>wasserfeste Tinte und Papier</u>.</li>
      <li>Benutzen Sie bei der Niederschrift einen <u>festen Untergrund</u>, sodass die Wörter nicht durchdrücken.</li>
      <li>Benutzen Sie einen <u>sicheren Ort</u> zum Aufbewahren, beispielsweise einen Safe.</li>
    </ol>
    <h4>6.2. Zusätzliche Sicherung der Wortliste in einem Passwort-Manager </h4>
    <ol type="a">
      <li>Sie haben während des Einrichtungs-Prozesses der 360X Art SafeKeeping App die Möglichkeit, Ihre Wortliste zusätzlich in einem Passwort-Manager zu speichern.</li>
      <li>Moderne Passwort-Manager sind end-to-end verschlüsselt und bieten Ihnen aus diesem Grund einen guten Schutz vor digitalem Diebstahl.</li>
      <li>Wichtig dabei ist, dass Sie sich an folgende Bedingungen halten:
  <ul>
    <li>Nutzen Sie einen Passwort-Manager, der vertrauenswürdig ist. </li>
    <li>Verwenden Sie diesen Passwortmanager nur auf Ihrem Smartphone.</li>
  </ul>
      </li>
    </ol>
    <h4>6.3. Welche anderen Möglichkeiten gibt es, meine Wortliste zu verwahren?</h4>
    <table className="mt-4">
  <thead>
    <tr>
      <th>Methode</th>
      <th>Ort</th>
      <th>Diebstahlschutz (digital)</th>
      <th>Diebstahl (physisch)</th>
      <th>Haltbarkeit</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Gedrucktes Papier</td>
      <td>Tresor</td>
      <td>Stark</td>
      <td>Stark</td>
      <td>Feuer-, Wasseranfällig</td>
    </tr>
    <tr>
      <td>Handgeschriebene Notiz</td>
      <td>Tresor</td>
      <td>Stark</td>
      <td>Stark</td>
      <td>Feuer-, Wasseranfällig</td>
    </tr>
    <tr>
      <td>USB-Stick</td>
      <td>Zu Hause</td>
      <td>Stark</td>
      <td>Schwach</td>
      <td>Fragil</td>
    </tr>
    <tr>
      <td>Passwort-Manager</td>
      <td>Smartphone</td>
      <td>Stark (starkes Passwort, modernes Smartphone)</td>
      <td>Stark</td>
      <td>Haltbar (Sicherung über verschlüsseltes Online-Backup)</td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      