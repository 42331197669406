import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isExternal, smoothScroll } from '../../utils/helper';

export default function Button({className, href, label, theme, onSubmit, onClick, ...props}) {

  const classNames = classnames("button disabled:cursor-not-allowed leading-[60px] inline-block px-[50px] uppercase font-medium transition-colors",
    {'text-purple bg-red hover:bg-purple hover:text-red': theme === 'light', ' txt-purple bg-red hover:bg-beige-dark ': theme === 'dark'},
    className);

  if(onClick) {
    return (
      <button className={classNames} onClick={onClick} {...props}>{props.children}</button>
    )
  }

  if(props.type === 'submit') {
    return (
      <button className={classNames} {...props}>{props.children}</button>
    )
  }

  return (
    <a className={classNames} href={href} 
      rel={isExternal(href)  || props.target==='_blank' ? 'noreferrer': undefined}
      target={isExternal(href) || props.target==='_blank' ? '_blank': undefined}
      onClick={href && href.startsWith('#') ? (e) => smoothScroll(e, href): undefined}>{props.children}</a>
  )
}

Button.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  href: PropTypes.string,
  label: PropTypes.string,
}

Button.defaultProps = {
  theme: 'light'
}