import React, {useState, createRef, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import useInView from "react-cool-inview";
import lottie from 'lottie-web';
import animation from '../../animations/howitworks_animation.json';
import * as howStyles from './HowItWorks.module.css';
import Container from '../../components/Container';
import Headline from '../../components/Headline';
import Grid from '../../components/Grid';
import Copy from '../../components/Copy';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination
} from 'swiper';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const HowItWorks = ({...props}) => {

  let animContainer = createRef();
  const anim = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const paginationRef = useRef(null)

  const [currentIndex, setCurrentIndex] = useState(0);
  const { observe } = useInView({
    // Stop observe when the target enters the viewport, so the "inView" only triggered once
    // For better UX, we can grow the root margin so the image will be loaded before it comes to the viewport
    unobserveOnEnter: true,
    onEnter: () => {
      anim.current?.playSegments([0, 77], true);
    }
  });

  useEffect(() => {

    anim.current = lottie.loadAnimation({
      container: animContainer.current,
      loop: false,
      autoplay: false,
      animationData: animation,
      name: "anim",
      speed: 2
    });
    return () => anim.current?.destroy(); // optional clean up for unmounting
  }, []);

  const onSlideChange = (swiper, anim) => {
    const { activeIndex, realIndex } = swiper;
    let workingIndex = activeIndex - 1;
  
    if(workingIndex === props.slides.length) {
      workingIndex = 0;
    }
    if(workingIndex === -1) {
      workingIndex = 0;
    }
    const { start, end } = props.slides[realIndex];
    anim.current?.stop();
    if(realIndex === (currentIndex + 1)) {
      anim.current?.playSegments([start, end], true);
    } else {
      if(realIndex === (currentIndex - 1)) {
        anim.current?.playSegments([props.slides[currentIndex].end, end], true);
      } else if (currentIndex === props.slides.length - 1 && realIndex === 0) {
        anim.current?.playSegments([props.slides[currentIndex].end, 850], true);
        anim.current?.playSegments([start, end], false);
      } else if (currentIndex === 0 && realIndex === props.slides.length - 1) {
        anim.current?.playSegments([props.slides[0].end, 0], true);
        anim.current?.playSegments([850, props.slides[realIndex].end], false);
      } else {
        anim.current?.playSegments([end-1, end], true);
      }
    }
    setCurrentIndex(workingIndex);
  }

  const pagination = {
    "clickable": true,
    el: paginationRef.current,
    "renderBullet": function (index, className) {
      return '<span class="' + className + '"></span>';
    }
  }
  return (
    <section id="how-it-works" className='bg-purple py-section md:py-[160px]'>
      <Container>
        <div className={howStyles['howItWorks']}>
          <div
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="600"
              data-sal-easing="ease-out-back"
            >
            <Grid>
              <div className="col-span-6 sm:col-span-3 md:col-span-6" ref={observe}>
                <Headline as="h2" theme="contrast">{props.headline}</Headline>
                <Swiper
                  onSlideChange={(swiper) => onSlideChange(swiper, anim)}
                  pagination={pagination}
                  loop
                  navigation={{
                    prevEl: prevRef.current, // Assert non-null
                    nextEl: nextRef.current, // Assert non-null
                  }}
                  slidesPerView={1}>
                    {props.slides.map((el, i) => {
                        return (
                          <SwiperSlide key={`${i}_slide`}>
                            <div key={`benefit_${i}_container`}>
                              <Headline as="h3" className="mb-2 pt-6 md:pt-10">{el.headline}</Headline>
                              <Copy className="text-white">{el.copy}</Copy>
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }
                  
                </Swiper>
              </div>
              <div className="col-span-6 sm:col-span-3 md:col-span-6">
                <div ref={animContainer} className={howStyles['animationContainer']}></div>
              </div>          
            </Grid>
            <div className="flex items-center justify-center">
              <div ref={prevRef} className="cursor-pointer">
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.83691 35.5V1.5H35.8369V35.5H1.83691Z" stroke="#F8F7F3" stroke-width="2"/>
                  <path d="M29.8155 18.6068H7.85916M7.85916 18.6068L16.1105 10.3555M7.85916 18.6068L16.1105 26.8581" stroke="#F8F7F3" stroke-width="2"/>
                </svg>
              </div>
              <div ref={paginationRef} className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>
              <div ref={nextRef} className="cursor-pointer">
                <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.16309 35.5V1.5H35.1631V35.5H1.16309Z" stroke="#F8F7F3" stroke-width="2"/>
                  <path d="M7.18543 18.6068H29.1418M29.1418 18.6068L20.8905 10.3555M29.1418 18.6068L20.8905 26.8581" stroke="#F8F7F3" stroke-width="2"/>
                </svg>
              </div>  
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

let Slide = PropTypes.shape({
    headline: PropTypes.string,
    copy: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number
})

HowItWorks.propTypes = {
  headline: PropTypes.string,
  slides: PropTypes.arrayOf(Slide)
}

HowItWorks.defaultProps = {
  headline: "",
  slides: [],
}

export default HowItWorks;
