import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isExternal, smoothScroll } from '../../utils/helper';

const FooterNav = ({items,...props}) => {

  const {className, ...rest} = props;

  const menuClasses = classnames("");

  return (
    <ul className={menuClasses}{...rest}>
    {
      items && items.map((el, i) => {
        return <li className="block mb-3 md:mb-[15px]" key={i}>
          <a className="font-body-extended hover:underline hover:underline-offset-2 uppercase leading-nav text-xs md:text-xl" href={el.href} 
            rel={isExternal(el.href) ? 'noreferrer': undefined}
            target={isExternal(el.href) ? '_blank': undefined}
            onClick={el.href && el.href.startsWith('#') ? (e) => smoothScroll(e, el.href): undefined}>{el.label}</a>
        </li>;
      })
    }
    </ul>
  )
}

FooterNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
      smoothScroll: PropTypes.bool
    })
  )
}

export default FooterNav;
