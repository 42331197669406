import React from 'react';
import classnames from 'classnames';

const Grid = ({...props}) => {

  const {className, ...rest} = props;

  const classNames = classnames("grid grid-cols-6 gap-5 md:grid-cols-12 md:gap-42", className);

  return (
    <div className={classNames} {...rest}>{props.children}</div>
  )
}

export default Grid;
