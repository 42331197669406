import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Legal/Legal.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>1. Angaben gemäß § 5 Telemediengesetz (TMG)</h2>
    <br />
Name des Anbieters: 
    <br />
360X Art AG<br />
Bockenheimer Anlage 46<br />
60322 Frankfurt am Main<br />
Deutschland<br />
    <br />
Vertretungsberechtigter:<br />
Vorstand: Shirin Marquart<br />
Vorsitzender des Aufsichtsrats: Dr. Michael Koch<br />
    <br />
Registereintrag:<br />
Amtsgericht Düsseldorf<br />
HRB 92416<br />
    <br />
Umsatzsteuer-Identifikationsnummer (§ 27a des Umsatzsteuergesetzes):<br />
DE350921970<br />
    <br />
Kontaktdaten:<br />
E-mail: info@360xart.de<br />
    <br />
Hinweis gemäß § 3 (2) Wertpapierinstitutsgesetz (WpIG):<br />
    <p>Die 360X Art AG ist vertraglich gebundener Vermittler im Sinne des § 3 (2) WpIG und wird bei der Vermittlung von Finanzinstrumenten gemäß § 2 (2) Nr. 3 WpIG ausschließlich für Rechnung und unter Haftung der Effecta GmbH, Florstadt, tätig. Die 360X Art AG ist bei der BaFin als gebundener Vermittler gemeldet: BaFin-Register gebundener Vermittler (Registernummer: 80178941).</p>
    <h2>2. Verantwortlicher im Sinne des § 18 Abs. 2 Medienstaatsvertrag (MStV)</h2>
Shirin Marquart,  Bockenheimer Anlage 46, 60322, Frankfurt am Main, Deutschland

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      