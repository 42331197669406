import React, {useState} from 'react';
import PropTypes from 'prop-types'
import Container from '../Container';
import Logo from '../Logo';
import scrollTo from 'gatsby-plugin-smoothscroll';
import classnames from 'classnames';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
export default function MainNav({items,logoOnly,...props}) {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasFirstScroll, setHasFirstScroll] = useState(false);

  const [headerStyle, setHeaderStyle] = useState({
    transition: 'all 200ms ease-in'
  });

  const smoothScroll = (e, target) => {
    e.preventDefault();
    if(isMenuOpen) {
      setIsMenuOpen(false);
      setTimeout(() => {
        scrollTo(`${target}`);
      },250);
    } else {
      scrollTo(`${target}`);
    }
  }

  const menuClasses = classnames('top-0 md:ml-auto md:block md:relative md:right-auto md:left-auto md:bg-transparent md:h-auto md:overflow-visible',
    'transition-all duration-300 ease-in-out h-screen block absolute bg-red right-0 left-0 flex flex-col items-center justify-center',
    {'max-h-screen': isMenuOpen, 'overflow-hidden max-h-0': !isMenuOpen}
  );

  const onMenuToggle = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  }

  useScrollPosition(
    ({ prevPos, currPos }) => {

      if(!hasFirstScroll && currPos.y < -1) {
        setHasFirstScroll(true);
      }

      if(isMenuOpen) {
        return;
      }

      const isVisible = (currPos.y > prevPos.y) || currPos.y > -150;
      
      const shouldBeStyle = {
        visibility: isVisible ? 'visible' : 'hidden',
        transition: `all 250ms ${isVisible ? 'ease-in-out' : 'ease-out'}`,
        transform: isVisible ? 'none' : 'translate(0, -100%)'
      }

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;

      setHeaderStyle(shouldBeStyle)
    },
    [headerStyle, isMenuOpen],
    null,
    false,
    0
  )

  return (
    <nav className={classnames("fixed right-0 left-0 w-full z-50")} style={{...headerStyle}}>
      <Container>
        <div className="flex pt-5 md:pt-10">
          {!logoOnly && (<a href="#" onClick={(e) => smoothScroll(e,'#top')} className="z-20" title=""><Logo animate={hasFirstScroll}/></a>)}
          {logoOnly && (<a href="/" className="z-20" title="Back to 360x Art"><Logo /></a>)}
          {!logoOnly && (<a href="#" onClick={(e) => onMenuToggle(e)} className='ml-auto md:hidden uppercase text-sm z-20 font-medium leading-6'>
            {isMenuOpen && 'Close'}
            {!isMenuOpen && 'Menu'}
          </a>)}
          {!logoOnly && (<ul className={menuClasses}>
            {
              items && items.map((el, i) => {
                return <li key={i} className="inline-flex md:pl-20 my-3 md:my-0">
                  <a className="font-medium hover:underline hover:underline-offset-2 uppercase tracking-widest leading-nav text-2xl md:text-base" href={el.href} onClick={(e) => smoothScroll(e, el.href)}>{el.label}</a>
                </li>;
              })
            }
          </ul>)}
        </div>
      </Container>
    </nav>
  )
}

MainNav.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string
    })
  ),
  logoOnly: PropTypes.bool
}

MainNav.defaultProps = {
  logoOnly: false
}

