import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Legal/Legal.js";
import Button from '../components/Button';
import Headline from '../components/Headline';
import Grid from '../components/Grid';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Grid mdxType="Grid">
  <div className="col-span-12 lg:col-span-10">
    <div className="download-box">
      <Headline className="mb-2 sm:mb-0" as="h5" mdxType="Headline">KAWS (Brian Donnelly), Black Spot</Headline>
      <Button href="/pdfs/Basisinformationsblatt_r.a.d.a.r. GmbH.pdf" target="_blank" theme="light" mdxType="Button">Herunterladen</Button>
    </div>
  </div>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      