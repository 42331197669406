import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Legal/Legal.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>Inhaltsübersicht</h2>
    <ol>
  <li><a href="#1">1. Anwendungsbereich</a></li>
  <li><a href="#2">2. Nutzung des Dienstes</a></li>
  <li><a href="#3">3. Grundlegende Funktionalitäten</a></li>
  <li><a href="#4">4. Geistiges Eigentum und beschränkte Lizenz</a></li>
  <li><a href="#5">5. Verpflichtungen und Zuständigkeiten</a></li>
  <li><a href="#6">6. Haftung und Freistellung</a></li>
  <li><a href="#7">7. Vergütung</a></li>
  <li><a href="#8">8. Elektronische Kommunikation</a></li>
  <li><a href="#9">9. Dauer und Beendigung</a></li>
  <li><a href="#10">10. Datenschutz</a></li>
  <li><a href="#11">11. Vertrauliche Informationen</a></li>
  <li><a href="#12">12. Regeln für die Auslegung</a></li>
  <li><a href="#13">13. Sonstiges</a></li>
    </ol>
    <h2>Anhänge:</h2>
    <ol>
  <li><a href="#ANB1">ANB Anhang 1</a></li>
  <li><a href="#ANB2">ANB Anhang 2</a></li>
  <li><a href="#ANB3">ANB Anhang 3</a></li>
    </ol>
    <h2 id="1">1. Anwendungsbereich</h2>
    <p>1.1 Diese Nutzungsbedingungen (einschließlich ihrer Anhänge) (die “Allgemeinen Nutzungsbedingungen“) regeln den Zugang und die Nutzung der Website <a href="https://www.360xart.com/">https://www.360xart.com/</a> (und ihrer Unterwebsites) sowie der Dienste (einschließlich der Basisfunktionalitäten und der zusätzlichen Dienste, jeweils wie unten definiert), die über die von 360X Art AG betriebenen Website bereitgestellt werden oder zugänglich sind (der „Dienst“).</p>
    <p>Die 360X Art AG hat ihren Sitz in Bockenheimer Anlage 46, 60322, Frankfurt am Main, Deutschland und ist im Handelsregister des Amtsgerichts Düsseldorf unter der Nummer HRB 92416 eingetragen („360X Art“).</p>
    <p>1.2 Diese Allgemeinen Nutzungsbedingungen regeln das Rechtsverhältnis zwischen 360X Art und jedem Dritten, der den Dienst nutzt (der bzw. die „Nutzer“ und gemeinsam mit 360X Art nachfolgend auch als „Parteien“ und jeder Dritte bzw. 360X Art als „Partei“ bezeichnet) ) bezüglich der Nutzung des Dienstes.</p>
    <p>1.3 Diese Allgemeinen Nutzungsbedingungen gelten für alle Verbesserungen, Aktualisierungen und Änderungen des Dienstes.</p>
    <p>1.4 Diese Allgemeinen Nutzungsbedingungen gelten auch für alle (zusätzlichen) Dienste, die über den Dienst verfügbar und/oder zugänglich sind. Diese Dienste können von 360X Art oder Dritten angeboten werden. Die Nutzung dieser (zusätzlichen) Dienste können (i) zusätzlichen durch den Nutzer bereitgestellten Informationen und/oder (ii) zusätzlichen Nutzungsbedingungen des jeweiligen Dienstanbieters („zusätzliche Nutzungsbedingungen“) unterliegen. Ein Nutzer kann daher aufgefordert werden, zusätzliche Nutzungsbedingungen zu akzeptieren, wenn er über den Dienst auf bestimmte weitere Dienste zugreift. Wenn und soweit die zusätzlichen Nutzungsbedingungen oder andere spezifischere Nutzungsbedingungen im Widerspruch zu diesen Bedingungen stehen, haben die zusätzlichen Nutzungsbedingungen oder diese anderen spezifischeren Nutzungsbedingungen Vorrang vor diesen Allgemeinen Nutzungsbedingungen.</p>
    <p>1.5 Diese Allgemeinen Nutzungsbedingungen umfassen auch die folgenden Bestimmungen die integraler Bestandteil dieser Allgemeinen Nutzungsbedingungen sind:</p>
    <p>(a)	die Beschreibung der grundlegenden Funktionalitäten des Dienstes ANB Anhang 1 (Beschreibung des Dienstes - Allgemeiner Dienst); und</p>
    <p>(b)	die technische Spezifikation und die IT-Sicherheitsvorschriften gemäß ANB Anhang 2 (IT-Sicherheitsanforderungen);</p>
    <p>(c)	die Zusatzbedingungen für die separat von der Website betriebene zugangsbeschränkte Plattform der 360X Art AG zur Erbringung von Vermittlungsdiensten in ANB Anhang 3 (Zusatzbedingungen);</p>
    <p>und werden der ANB Anhang 1 (Leistungsbeschreibung – Allgemeiner Dienst), der ANB Anhang 2 (IT-Sicherheitsanforderungen) und der ANB Anhang 3 (Zusatzbedingungen – Plattform) gemeinsam als „ANB Anhänge“bezeichnet.</p>
    <h2 id="2">2. Nutzung des Dienstes</h2>
    <p>2.1 Einem Nutzer wird ein Nutzungsrecht gemäß den vorliegenden Allgemeinen Nutzungsbedingungen eingeräumt. Jeder Nutzer verpflichtet sich, diese Allgemeinen Nutzungsbedingungen einzuhalten. Mit der Nutzung des Dienstes (und jedenfalls mit der Erstellung eines Nutzerkontos) akzeptiert der Nutzer diese Allgemeinen Nutzungsbedingungen und stimmt ihnen zu. Ein Nutzer, der diese Allgemeinen Nutzungsbedingungen nicht akzeptiert und ihnen nicht zustimmt, darf den Dienst nicht nutzen.</p>
    <p>2.2 360X Art stellt den Nutzern den Dienst gemäß diesen Allgemeinen Nutzungsbedingungen 24 Stunden am Tag, 7 Tage die Woche zur Verfügung oder sorgt dafür, dass der Dienst zur Verfügung steht, mit Ausnahme von: (i) geplanten Ausfallzeiten (die 360X Art im Voraus ankündigt) und (ii) Nichtverfügbarkeiten, die durch Umstände verursacht werden, die außerhalb der zumutbaren Kontrolle des betreffenden Nutzers liegen, wie z.B. höhere Gewalt, staatliche Maßnahmen, Überschwemmungen, Feuer, Erdbeben, zivile Unruhen, Terroranschläge, Streiks oder andere arbeitsrechtliche Unterbrechungen (die nicht die Angestellten des Nutzers betreffen), Ausfälle oder Verzögerungen bedingt durch Internet Service Provider oder Denial-of-Service-Attacken (“Betriebszeit”).</p>
    <h2 id="3">3. Grundlegende Funktionalitäten</h2>
    <p>3.1 Die grundlegenden Funktionalitäten des Dienstes (der „allgemeine Dienst“) sind in ANB Anhang 1 (Beschreibung des Dienstes - Allgemeiner Dienst) beschrieben. Die Basisfunktionalitäten werden nur zu Informationszwecken zur Verfügung gestellt.</p>
    <p>3.2 Der Nutzer kann auch zusätzliche Dienste in Anspruch nehmen (die „Zusatzdienste“) wie z.B. die Vermittlungsdienste nach Maßgabe der in ANB Anhang 3 (Zusatzbedingungen – Plattform) enthaltenen Zusatzbedingungen. Diese Zusatzdienste werden von 360X Art oder Drittanbietern bereitgestellt. Voraussetzung für die Nutzung der Zusatzdienste ist, dass der Nutzer die für diese Dienste geltenden zusätzlichen Nutzungsbedingungen (neben diesen Allgemeinen Nutzungsbedingungen) akzeptiert hat. Die Nutzung der Zusatzdienste kann die Bereitstellung zusätzlicher Informationen durch den Nutzer erforderlich machen.</p>
    <p>3.3 Weder diese Allgemeinen Nutzungsbedingungen noch die Erstellung eines Nutzerkontos, noch die Bereitstellung und/oder Nutzung der Basisfunktionalitäten begründen eine Verpflichtung von 360X Art zur Bereitstellung der Zusatzleistungen für den Nutzer oder das Recht des Nutzers, die Bereitstellung der Zusatzleistungen von 360X Art oder einem Drittanbieter zu verlangen.</p>
    <h2 id="4">4. Geistiges Eigentum und beschränkte Lizenz</h2>
    <p>4.1 360X Art gewährt dem Nutzer hiermit ein begrenztes, widerrufliches, nicht ausschließliches, nicht übertragbares und nicht abtretbares Recht, den Dienst in Übereinstimmung mit diesen Allgemeinen Nutzungsbedingungen für die Dauer des Zugangs des Nutzers zum Dienst zu nutzen.</p>
    <p>4.2 Der Dienst enthält Daten und Informationen aller Art, die marken- und/oder urheberrechtlich zu Gunsten von 360X Art oder im Einzelfall auch zu Gunsten von Dritten geschützt sind. Der Nutzer darf keine Eigentumsvermerke von 360X Art auf irgendeiner Kopie des Dienstes löschen, entfernen oder in irgendeiner Weise unkenntlich machen. Es ist nicht gestattet, den Dienst ganz oder teilweise herunterzuladen, zu vervielfältigen und/oder zu verbreiten. Erlaubt ist die technisch bedingte Vervielfältigung zum Zwecke des Browsing, soweit diese Handlung nicht kommerziellen Zwecken dient, sowie die dauerhafte Vervielfältigung zum eigenen Gebrauch. Darüber hinaus darf der Nutzer Warenzeichen, Logos oder Marken von 360X Art nur mit vorheriger ausdrücklicher schriftlicher Genehmigung von 360X Art verwenden.</p>
    <p>4.3 Der Nutzer gewährt 360X Art hiermit eine unbefristete, nicht ausschließliche, geografisch unbegrenzte, unterlizenzierbare, gebührenfreie Lizenz in Bezug auf alle Inhalte, die der Nutzer 360X Art zur Verfügung gestellt hat („lizenzierte Inhalte“), um auf die lizenzierten Inhalte zuzugreifen, sie (ganz oder teilweise) zu vervielfältigen, zu speichern, elektronisch zu verbreiten und darzustellen (in Papierform, elektronischer Form oder in einer anderen Form), um den Zugang des Nutzers zu und/oder die Nutzung des Dienstes zu ermöglichen, zu erleichtern und zu verwalten (einschließlich der technischen Prozesse, der IT- und Cybersicherheit und der korrekten Funktionsfähigkeit des Dienstes sowie der Verbesserung und Entwicklung neuer Funktionalitäten des Dienstes). Der Nutzer sichert hiermit zu, dass er alle Rechte (einschließlich aller Rechte an geistigem Eigentum) besitzt, die zur Erfüllung seiner vorstehenden Verpflichtungen erforderlich sind, und dass er keine Rechte Dritter an den lizenzierten Inhalten verletzen wird. Soweit 360X Art durch die Nutzung von lizenzierten Inhalten geistiges Eigentum erzeugt, erwirbt der Nutzer keine Rechte an diesem geistigen Eigentum. 360X Art wird lizenzierte Inhalte weitestgehend so verwenden, dass der betreffende Nutzer nicht identifiziert werden kann.</p>
    <p>4.4 360X Art behält sich das Recht vor, den Dienst ständig weiterzuentwickeln und zu verändern.</p>
    <h2 id="5">5. Verpflichtungen und Zuständigkeiten</h2>
    <p>5.1 Jeder Nutzer ist verpflichtet:</p>
    <p>(a)	den Dienst ordnungsgemäß zu nutzen und jede Nutzung für missbräuchliche und/oder rechtswidrige Zwecke zu unterlassen; insbesondere darf der Nutzer nicht (i) Daten, Datensammlungen, Datenbanken oder Softwarefunktionalitäten auf den Dienst hochladen, speichern oder anderen zur Verfügung stellen, die (1) Schadsoftware enthalten oder anderweitig nicht den technischen Anforderungen für die Nutzung des Dienstes gemäß ANB Anhang 2 (IT-Sicherheitsanforderungen) entsprechen oder die (2) gewerbliche Schutzrechte oder Geschäftsgeheimnisse Dritter verletzen; (ii) den Dienst in einer Weise nutzen, die die Nutzung des Dienstes durch andere Nutzer erheblich einschränkt oder verhindert; (iii) kriminelle, sittenwidrige, volksverhetzende, verleumderische oder anderweitig illegale Inhalte auf den Dienst hochladen und/oder anderen Nutzern zugänglich machen.</p>
    <p>(b)	auf eigene Kosten alle technischen Voraussetzungen (Hardware, Software und Telekommunikation) für die Nutzung des Dienstes in Übereinstimmung mit diesen Allgemeinen Nutzungsbedingungen in seinem Verantwortungsbereich gemäß diesen Allgemeinen Nutzungsbedingungen zu implementieren, zu betreiben und zu warten.</p>
    <p>(c)	360X Art in angemessener Weise bei der Lösung von technischen Problemen und der Durchführung von Wartungsdienste in Bezug auf den Dienst zu unterstützen.</p>
    <p>(d)	nur Personen Zugang zum Dienst zu gewähren, die von dem betreffenden Nutzer ermächtigt sind, im Namen und im Auftrag des Nutzers in Bezug auf jegliche Aktion (einschließlich der Abgabe und des Empfangs von Willenserklärungen) im Dienst zu handeln. </p>
    <p>(e)	alle geltenden Sicherheitsstandards gemäß ANB Anhang 2 (IT-Sicherheitsanforderungen) und alle geltenden Service-Levels einzuhalten, einschließlich, aber nicht beschränkt auf jegliche Passwortschutzmaßnahmen. </p>
    <p>(f)	unverzüglich und auf eigene Kosten alle in seinem Besitz oder unter seiner Kontrolle befindlichen Informationen zur Verfügung zu stellen, die von 360X Art billigerweise angefordert werden, um 360X Art in die Lage zu versetzen, geltende Gesetze einzuhalten oder einem Informationsersuchen einer Regulierungsbehörde, einer anderen staatlichen Stelle, einer Regulierungs- oder Aufsichtsbehörde oder eines Gerichts nachzukommen. Alle von den Nutzern zur Verfügung gestellten Informationen unterliegen den Vertraulichkeitsverpflichtungen gemäß diesen Allgemeinen Nutzungsbedingungen. </p>
    <p>(g)	Bedenken über den Betrieb des Dienstes oder das Verhalten eines anderen Nutzers ohne unnötige Verzögerung an 360X Art zu richten, die eine Untersuchung einleiten und eine Entscheidung in der Angelegenheit treffen kann.</p>
    <p>(h)	360X Art unverzüglich über fehlende Eigenschaften oder Mängel des Dienstes zu informieren (Mangelanzeige) und dabei die fehlenden Eigenschaften oder die Mängel zu beschreiben und im letzteren Fall alle der Fehlerbehebung dienlichen Informationen zu übermitteln, soweit sie dem Nutzer zur Verfügung stehen.</p>
    <p>(i)	alle von ihm im Zusammenhang mit der Nutzung des Dienstes, insbesondere bei der Erstellung eines Nutzerkontos, gemachten Angaben vollständig, wahrheitsgetreu und richtig anzugeben, und diese Angaben während der Dauer seines Zugangs zum Dienst unverzüglich zu aktualisieren, sobald er von Änderungen Kenntnis erhält. </p>
    <p>(j)	dass er oder jeder, der in seinem Namen tätig ist, die Verpflichtungen nach Maßgabe dieser Allgemeinen Nutzungsbedingungen sowie alle geltenden Gesetze, die den Zugang und die Nutzung des Dienstes regeln, einzuhalten. </p>
    <p>(k)	alle Informationen zu überprüfen, die im Dienst angezeigt werden, und sicherzustellen, dass alle von ihm an den Dienst übermittelten Daten vollständig und korrekt sind.</p>
    <p>5.2 Kein Nutzer darf direkt oder indirekt: </p>
    <p>(a)	den Dienst für einen anderen als den in diesen Allgemeinen Nutzungsbedingungen ausdrücklich vereinbarten oder von 360X Art ausdrücklich genehmigten Zweck nutzen oder für solche Zwecke darauf zugreifen.</p>
    <p>(b)	den Dienst in einer Weise nutzen oder darauf zugreifen, die den Ruf von 360X Art schädigt oder beeinträchtigt.</p>
    <p>(c)	in irgendeiner Form systematisch oder automatisiert Daten im Zusammenhang mit dem Dienst erheben, mit Ausnahme der Speicherung von Inhalten wie es von der Funktionalität des Dienstes vorgesehen ist.</p>
    <p>(d)	den Dienst in einer Weise nutzen oder darauf zugreifen, die dazu führt, dass 360X Art gegen ein geltendes Gesetz oder eine gesetzliche Pflicht oder Verpflichtung gegenüber einer Person verstoßen würde.</p>
    <p>(e)	den Dienst nutzen, um eine Software, ein Produkt oder ein System zu erstellen, das dem Dienst ähnlich ist. </p>
    <p>(f)	den Dienst unter Verletzung geltender Rechtsvorschriften oder von Rechten anderer Personen, insbesondere deren geistige Eigentumsrechte, nutzen. </p>
    <p>(g)	den Quellcode oder die zugrunde liegenden Ideen, Algorithmen oder die Organisation des Dienstes oder eines Teils davon zerlegen, dekompilieren, reverse engineeren oder mit anderen Mitteln versuchen, sie zu erkunden. </p>
    <p>(h)	Inhalte des Dienstes kopieren, vervielfältigen, herunterladen, veröffentlichen, übermitteln oder anderweitig reproduzieren, übertragen, verteilen, speichern, zusammenfassen oder anderweitig in irgendeiner Form oder mit irgendwelchen Mitteln in einer Weise nutzen, die von der Funktionalität des Dienstes abweicht. </p>
    <p>(i)	den Dienst in einer Weise nutzen oder darauf zugreifen, die die Stabilität der Systeme von 360X Art beeinträchtigt oder die Leistung des Systems von 360X Art in irgendeiner Weise stört.</p>
    <p>„Systeme“ bezeichnet die Computersysteme (einschließlich Software, Hardware, Betriebssysteme, Interneteinrichtungen, IT-Netze und informationstechnische Infrastruktur).</p>
    <p>(j)	den Dienst in einer Weise nutzen, die technische, administrative, verfahrenstechnische oder sicherheitstechnische Maßnahmen von 360X Art unterbricht oder umgeht oder die Leistung des Dienstes unterbricht oder verschlechtert oder die Anfälligkeit der Systeme oder Daten von 360X Art testet.</p>
    <p>(k)	Viren oder andere Computerprogramme übertragen, die das System oder die Daten von 360X Art beschädigen, nachteilig beeinflussen, heimlich abfangen oder unzugänglich machen können. </p>
    <p>(l)	Unterlizenzen für den Dienst oder Zugangsinformationen vergeben, verkaufen, vermieten, verleihen oder übertragen oder Einnahmen aus der Nutzung des Dienstes erzielen, unabhängig davon, ob dies zu direkten kommerziellen Zwecken erfolgt oder nicht.</p>
    <p>5.3 Im Falle eines Verstoßes gegen Ziffer 6.1 (a), (b) ist 360X Art berechtigt, unzulässige oder von Dritten beanstandete Inhalte zu sperren oder das Nutzerkonto in angemessenem Umfang bis zur Klärung des Sachverhalts für die weitere Nutzung zu sperren. Das Kündigungsrecht von 360X Art gemäß Ziffer 10 bleibt unberührt.</p>
    <p>5.4 Nach dem derzeitigen Stand der Technik ist es nicht möglich, Softwarefehler unter allen Bedingungen, unter denen Software eingesetzt wird, auszuschließen. 360X Art ist bestrebt, alle angemessenen Anstrengungen zu unternehmen, um sicherzustellen, dass der Dienst in Übereinstimmung mit diesen Allgemeinen Nutzungsbedingungen allgemein nutzbar und funktionsfähig ist.</p>
    <h2 id="6">6. Haftung und Freistellung</h2>
    <p>6.1 360X Art übernimmt keine Gewähr dafür, dass die Nutzung des Dienstes, der für den Betrieb des Dienstes erforderlichen Software oder des Inhalts durch den Nutzer nicht die Rechte Dritter verletzt. </p>
    <p>6.2 360X Art haftet gegenüber dem Nutzer nur bei Vorsatz oder grober Fahrlässigkeit sowie bei schuldhafter Verletzung von Leben, Körper oder Gesundheit durch ihre gesetzlichen Vertreter oder Erfüllungsgehilfen. Sofern 360X Art oder ihre gesetzlichen Vertreter oder Erfüllungsgehilfen eine wesentliche Vertragspflicht verletzen, haftet 360X Art dem Nutzer auch für einfache Fahrlässigkeit. Eine wesentliche Vertragspflicht ist eine Pflicht, deren Erfüllung die ordnungsgemäße Durchführung der Allgemeinen Nutzungsbedingungen überhaupt erst ermöglicht und auf deren Einhaltung eine Partei regelmäßig vertrauen darf. Bei einfach fahrlässiger Verletzung wesentlicher Vertragspflichten ist die Haftung von 360X Art auf den vertragstypischen, vorhersehbaren Schaden beschränkt. </p>
    <p>6.3 360X Art verstößt nicht gegen diese Allgemeinen Nutzungsbedingungen und haftet nicht für Verzögerungen bei der Erbringung oder Nichterbringung des Dienstes, wenn diese Verzögerungen oder Nichterbringung auf Ereignisse, Umstände oder Ursachen zurückzuführen sind, die außerhalb der Kontrolle von 360X Art liegen. </p>
    <p>6.4 360X Art ist nicht verantwortlich und übernimmt keine Haftung für Schäden aufgrund von Arbeitskampf und/oder höherer Gewalt. </p>
    <p>6.5 Der Nutzer stellt 360X Art von jeglicher Haftung, Verlusten, Ansprüchen, Schäden, Ausgaben und Kosten frei, die direkt oder indirekt durch einen Verstoß des Nutzers oder seiner gesetzlichen Vertreter oder Erfüllungsgehilfen gegen diese Allgemeinen Nutzungsbedingungen verursacht werden, insbesondere von Ansprüchen Dritter. </p>
    <p>6.6 360X Art haftet nicht für die von einem Nutzer gesetzten Hyperlinks, die in den Dienst aufgenommen werden und die auf externe Websites und/oder Inhalte verweisen.</p>
    <h2 id="7">7. Vergütung</h2>
    <p>360X Art erbringt die Leistung gegen die in ANB Anlage 1 (Leistungsbeschreibung - Allgemeine Leistung) jeweils detailliert festgelegte Vergütung (ggf. zuzüglich der gesetzlichen Mehrwertsteuer).</p>
    <h2 id="8">8. Elektronische Kommunikation</h2>
    <p>8.1 Jegliche Kommunikation an 360X Art oder an den Nutzer im Zusammenhang mit den Dienstleistungen und diesen Allgemeinen Nutzungsbedingungen erfolgt in der Regel unverschlüsselt per E-Mail oder auf einem anderen elektronischen Wege. Solange keine gegenteilige Mitteilung erfolgt, werden solche Mitteilungen an die folgenden E-Mail-Adressen gesendet: <a href="mailto:support@360xart.de">support@360xart.de</a> (wenn an 360X Art) oder an die vom Nutzer für sein Nutzerkonto angegebene E-Mail-Adresse (wenn an den Nutzer).  </p>
    <p>8.2 Jede elektronische Mitteilung im Zusammenhang mit diesen Allgemeinen Nutzungsbedingungen ist nur dann wirksam, wenn sie tatsächlich in lesbarer Form empfangen wird. </p>
    <p>8.3 Jede elektronische Mitteilung, die gemäß den Ziffern 9.1 und 9.2 nach 17.00 Uhr am Ort des Eingangs wirksam wird, wird am folgenden Werktag wirksam. </p>
    <h2 id="9">9. Dauer und Beendigung</h2>
    <p>9.1 Diese Allgemeinen Nutzungsbedingungen gelten auf unbestimmte Zeit, vorbehaltlich einer Kündigung gemäß dieser Ziffer 10. </p>
    <p>9.2 Sowohl 360X Art als auch der Nutzer können den durch diese Allgemeinen Nutzungsbedingungen geschlossenen Vertrag jederzeit mit einer Frist von zwei Wochen zum Monatsende in Textform (E-Mail oder Brief) kündigen. Das Recht zur fristlosen Kündigung des Vertrages aus wichtigem Grund bleibt unberührt. </p>
    <p>9.3 Darüber hinaus kann 360X Art bei missbräuchlicher Nutzung des Dienstes den Zugang des Nutzers zum Dienst mit sofortiger Wirkung (fristlos) kündigen und/oder sperren. Eine solche missbräuchliche Nutzung ist insbesondere in folgenden Fällen gegeben: </p>
    <p>(a)	jede erhebliche Verletzung dieser Allgemeinen Nutzungsbedingungen durch den Nutzer; </p>
    <p>(b)	jede Nutzung des Dienstes, die rechtswidrig oder irreführend ist oder die Rechte Dritter (einschließlich der Rechte an geistigem Eigentum) verletzt;</p>
    <p>(c)	jegliche Nutzung des Dienstes, die anders als in Absatz (b) beschrieben, den Ruf von 360X Art schädigen oder beeinträchtigen kann; </p>
    <p>(d)	jede übermäßige Ressourcennutzung, die die Stabilität des Systems von 360X Art beeinträchtigen oder die Leistung des Systems von 360X Art in irgendeiner Weise beeinträchtigen könnte. </p>
    <p>9.4 Mit dem Wirksamwerden der Kündigung dieser Allgemeinen Nutzungsbedingungen: </p>
    <p>(a)	stellt 360X Art dem Nutzer alle relevanten Inhalte in einem Standard-Datenformat zum Export oder Download zur Verfügung und benachrichtigt den Nutzer entsprechend. Nach Ablauf von 30 Tagen nach Erhalt einer solchen Benachrichtigung durch den Nutzer ist 360X Art nicht mehr verpflichtet, Inhalte des Nutzers zu unterhalten oder dem Nutzer zur Verfügung zu stellen.</p>
    <p>(b)	Der Nutzer ist verpflichtet, jegliche Software, die für den Betrieb des Dienstes erforderlich ist (in der jeweils aktuellen Fassung), von seinen Systemen zu löschen und 360X Art diese Löschung schriftlich (auch per E-Mail) zu bestätigen.</p>
    <p>(c)	Abgesehen von den in Absatz (a) genannten Fällen ist der Nutzer verpflichtet, alle Inhalte, die durch den Dienst, 360X Art, andere Nutzer oder Dritte generiert wurden oder sich darauf beziehen, von seinen Systemen zu löschen und 360X Art die Löschung schriftlich (auch per E-Mail) zu bestätigen.</p>
    <p>(d)	360X Art ist weiterhin berechtigt, die Inhalte des Nutzers zu verwenden und zu speichern, solange und soweit dies nach den geltenden Gesetzen und Vorschriften erforderlich ist. Der Nutzer erkennt an, dass für den Fall, dass 360X Art den Dienst weiterhin betreibt, die Technologie des Dienstes es 360X Art nicht ermöglicht, die Inhalte des Nutzers zu löschen, solange der Dienst betrieben wird.  </p>
    <h2 id="10">10. Datenschutz</h2>
    <p>10.1 360X Art kann die personenbezogenen Daten des Nutzers im Rahmen oder in Verbindung mit der Nutzung der von 360X Art gemäß diesen Allgemeinen Nutzungsbedingungen oder der Zusatzdienste gemäß den zusätzlichen Nutzungsbedingungen verarbeiten. </p>
    <p>10.2 Der Nutzer muss sich für die Dienste registrieren, indem er seine Zugangsdaten angibt. Die Angabe der Zugangsdaten ist verpflichtend, alle anderen Angaben können bei der Nutzung der Dienste freiwillig gemacht werden. Die Angabe weiterer obligatorischer oder freiwilliger Informationen ist in den zusätzlichen Nutzungsbedingungen beschrieben.</p>
    <p>10.3 360X Art verarbeitet (einschließlich Speicherung) die für die Erfüllung des Vertrages erforderlichen Zugangsdaten des Nutzers, bis der Nutzer sein Konto endgültig löscht. Darüber hinaus speichert 360X Art die vom Nutzer freiwillig gemachten Angaben für die Zeit der Nutzung der Dienste, sofern der Nutzer diese nicht vorher löscht. Die Rechtsgrundlage für die Verarbeitung ist Art. 6 (1) b DSGVO.</p>
    <p>10.4 Weitere Informationen und Details zum Datenschutz finden Sie in der Datenschutzerklärung auf der Website von 360X Art unter <a href="https://www.360xart.com/data-privacy/">https://www.360xart.com/data-privacy/</a>.</p>
    <h2 id="11">11. Vertrauliche Informationen</h2>
    <p>11.1 In Bezug auf alle (schriftlichen oder mündlichen) Informationen, die im Zusammenhang mit dem Geschäft, den Kunden oder den finanziellen oder sonstigen Angelegenheiten einer Partei verwendet werden oder sich anderweitig darauf beziehen, unabhängig davon, ob sie als „vertraulich“ gekennzeichnet sind oder nicht („vertrauliche Informationen“), auf die eine Partei („Empfänger“) durch die andere Partei oder im Namen der anderen Partei („offenbarende Partei“) durch, während oder in Verbindung mit der Nutzung des Dienstes zugreift oder diese offenlegt, verpflichtet sich der Empfänger gegenüber der offenbarenden Partei: </p>
    <p>(a) alle diese vertraulichen Informationen vertraulich zu behandeln; </p>
    <p>(b) diese vertraulichen Informationen nur für den Zweck zu verwenden, für den sie zur Verfügung gestellt wurden; </p>
    <p>(c) solche vertraulichen Informationen nur an seine Mitarbeiter oder Erfüllungsgehilfen weiterzugeben, wenn und soweit sie diese vertraulichen Informationen zur Erfüllung der Verpflichtungen des Empfängers oder im Zusammenhang mit dem ordnungsgemäßen Betrieb des Dienstes kennen müssen, stets vorausgesetzt, dass sie diese vertraulichen Informationen nicht für andere Zwecke als die, für die sie bereitgestellt wurden, verwenden; </p>
    <p>(d) angemessene Maßnahme zu ergreifen, um die Offenlegung solcher vertraulicher Informationen durch Dritte ohne die vorherige schriftliche Zustimmung der offenlegenden Partei zu verhindern, es sei denn, es handelt sich um eine Offenlegung an professionelle Berater des Empfängers oder um eine Offenlegung aufgrund von gesetzlichen Vorschriften oder einer entsprechenden Anordnung einer zuständigen Rechts- oder Aufsichtsbehörde; </p>
    <p>(e) ein angemessenes Maß an Sorgfalt anzuwenden, um alle diese vertraulichen Informationen zu schützen, auf jeden Fall aber nicht weniger als das Maß an Sorgfalt, das der Empfänger zum Schutz seiner eigenen vertraulichen Informationen anwendet.</p>
    <p>11.2 Die Vertraulichkeitsverpflichtungen gemäß dieser Ziffer 12 gelten nicht für vertrauliche Informationen, soweit diese Informationen: </p>
    <p>(a) der Öffentlichkeit zugänglich sind oder werden, es sei denn, dies ist das Ergebnis einer Verletzung dieser Klausel oder einer anderen Vertraulichkeitspflicht in Bezug auf diese Informationen; </p>
    <p>(b) dem Empfänger auf nicht vertraulicher Basis von einer Person zur Verfügung gestellt wurden oder werden, die nicht durch eine Vertraulichkeitsverpflichtung in Bezug auf diese Informationen gebunden ist oder der es aus anderen Gründen nicht untersagt ist, diese Informationen an den Empfänger weiterzugeben; </p>
    <p>(c) dem Empfänger vor der Offenlegung durch die offenlegende Partei bekannt war; oder </p>
    <p>(d) von oder für den Empfänger unabhängig von den von der offenlegenden Partei offengelegten Informationen entwickelt wird. </p>
    <p>11.3 Jede Vertragspartei ist berechtigt, vertrauliche Informationen offenzulegen, soweit dies erforderlich ist:</p>
    <p>(a) um den Empfänger in die Lage zu versetzen, seine Verpflichtungen zu erfüllen oder seine Rechte auszuüben; </p>
    <p>(b) durch ein anwendbares Gesetz oder durch ein Gericht, ein Schiedsgericht oder ein Verwaltungsgericht im Rahmen eines Verfahrens vor diesem, durch eine Regierungsbehörde oder eine Aufsichtsbehörde, die dies rechtmäßig verlangt, oder durch die Vorschriften einer Börse, vorausgesetzt, dass (soweit dies nicht durch ein Gesetz oder eine Anordnung eines Gerichts, eines Schiedsgerichts oder eines Verwaltungsgerichts, einer Regierungsbehörde oder einer Aufsichtsbehörde oder durch Börsenvorschriften untersagt ist) der Empfänger die offenlegende Partei unverzüglich benachrichtigt und sich mit ihr im Voraus über den Zeitpunkt und den Inhalt einer solchen Offenlegung ins Benehmen setzt; </p>
    <p>(c) um einem professionellen Berater des Empfängers, der zur Geheimhaltung dieser vertraulichen Informationen verpflichtet ist, entsprechende Anweisungen zu erteilen; </p>
    <p>11.4 Der Empfänger wird unverzüglich alle angemessenen Anweisungen befolgen, die ihm von Zeit zu Zeit von der offenlegenden Partei im Zusammenhang mit der Verwendung aller oder eines Teils der vertraulichen Informationen der offenlegenden Partei erteilt werden. </p>
    <p>11.5 Der Empfänger erwirbt kein Recht an vertraulichen Informationen, die der offenlegenden Partei gehören oder sich auf sie beziehen.</p>
    <p>11.6 Die Verpflichtungen in dieser Klausel bleiben bestehen, wenn das Recht der offenlegenden Partei und/oder des Empfängers zur Nutzung des Dienstes endet. </p>
    <h2 id="12">12. Regeln für die Auslegung</h2>
    <p>12.1 Jede nachstehende Bezugnahme auf diese Allgemeinen Nutzungsbedingungen ist so auszulegen, dass sie eine Bezugnahme auf die in Ziffer 1.5 genannten Dokumente einschließt. </p>
    <p>12.2 Jeder Verweis auf diese Allgemeinen Nutzungsbedingungen ist so auszulegen, dass er einen Verweis auf diese Allgemeinen Nutzungsbedingungen in ihrer jeweils geänderten Fassung beinhaltet.  </p>
    <p>12.3 Sofern nicht anders angegeben, sind Verweise in diesen Allgemeinen Nutzungsbedingungen auf eine Klausel oder einen ANB Anhang als Verweise auf diese Klausel oder diesen ANB Anhang dieser Allgemeinen Nutzungsbedingungen zu verstehen. </p>
    <p>12.4 Jede Bezugnahme auf eine Rechtsvorschrift ist als Bezugnahme auf die jeweils geltende Rechtsvorschrift unter Berücksichtigung einer Änderung, Erweiterung oder Wiederinkraftsetzung zu verstehen und schließt die Rechtsvorschrift ein, die durch sie geändert, erweitert oder wieder in Kraft gesetzt wird. </p>
    <h2 id="13">13. Sonstiges</h2>
    <p>13.1 Keine andere Partei als 360X Art ist berechtigt, ihre Rechte, Verbindlichkeiten oder Verpflichtungen ganz oder teilweise an Dritte abzutreten, zu übertragen, darüber Sicherheit zu bestellen oder anderweitig darüber zu verfügen.</p>
    <p>13.2 Sollten sich eine oder mehrere Bestimmungen dieser Allgemeinen Nutzungsbedingungen als unwirksam oder undurchführbar erweisen, so berührt dies die Wirksamkeit der übrigen Verpflichtungen nicht. Dies gilt auch für etwaige Lücken. Anstelle der unwirksamen oder undurchführbaren Bestimmung oder zur Ausfüllung einer Vertragslücke verpflichten sich die Parteien, die betreffende Bestimmung bzw. die Vertragslücke durch eine Regelung zu ersetzen, die dem beabsichtigten wirtschaftlichen Zweck der betreffenden Bestimmung bzw. der Vertragslücke am nächsten kommt. </p>
    <p>13.3 360X Art kann diese Allgemeinen Nutzungsbedingungen jederzeit ändern und die weitere Nutzung des Dienstes von der Zustimmung des Nutzers zu den geänderten Bedingungen abhängig machen. Bei einer Änderung wird 360X Art eine Mitteilung auf seiner Website veröffentlichen. Jeder Nutzer ist verpflichtet, die jeweils gültige Version dieser Allgemeinen Nutzungsbedingungen regelmäßig zu überprüfen. Durch die weitere Nutzung des Dienstes, nachdem 360X Art eine geänderte Version dieser Allgemeinen Nutzungsbedingungen zur Verfügung gestellt hat, erklärt sich der Nutzer mit dieser Änderung einverstanden. </p>
    <p>13.4 Die Parteien vereinbaren, dass diese Allgemeinen Nutzungsbedingungen, ihre Auslegung und alle damit zusammenhängenden außervertraglichen Verpflichtungen dem materiellen Recht der Bundesrepublik Deutschland unter Ausschluss des internationalen Privatrechts und des UN-Kaufrechts unterliegen. </p>
    <p>13.5 Für die Beilegung von Streitigkeiten, die sich aus oder im Zusammenhang mit diesen Allgemeinen Nutzungsbedingungen ergeben (einschließlich Streitigkeiten über das Bestehen, die Gültigkeit oder die Beendigung), sind die Gerichte in Frankfurt am Main, Deutschland, nicht ausschließlicher Gerichtsstand. </p>
    <h2 id="ANB1" className="text-center">ANB ANHANG 1<br />DIENSTBESCHREIBUNG - ALLGEMEINER DIENST</h2>
    <p>Der allgemeine Dienst, den 360X Art dem Nutzer zur Verfügung stellt, besteht aus den folgenden allgemeinen Basisfunktionalitäten:</p>
    <table>
  <thead>
    <tr>
      <th>Beschreibung der Dienstleistung</th>
      <th>Vergütung</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Website der 360X Art mit Basisinformationen und Kontaktmöglichkeit</td>
      <td>kostenfrei</td>
    </tr>
  </tbody>
    </table>
    <h2 id="ANB2" className="text-center">ANB ANHANG 2 <br />TECHNISCHE SPEZIFIKATIONEN UND IT-SICHERHEITSANFORDERUNGEN</h2>
    <p>In diesem ANB-Anhang 2 werden die vom Nutzer einzuhaltenden IT-Sicherheitsanforderungen dargelegt:</p>
    <p>Definierte Begriffe, auf die in diesem ANB-Anhang 2 Bezug genommen wird, haben die gleiche Bedeutung wie in den Allgemeinen Nutzungsbedingungen, sofern sie hier nicht ausdrücklich anders definiert sind.</p>
    <p>1. Der Nutzer muss angemessene und überprüfbare Kontrollen unterhalten, einschließlich der folgenden:</p>
    <ol type="a">
      <li>Schutz vor Computerviren, Würmern, trojanischen Pferden und anderer bösartiger Software;</li>
      <li>die Verwendung von Sicherheitssoftware in allen Bereichen seiner Systeme, die die Leistung oder Verfügbarkeit der Dienste beeinträchtigen können;</li>
      <li>Beschaffung und Aktualisierung von Signaturen und Sicherheitspatches für alle Systeme, sobald diese freigegeben werden; und</li>
      <li>ein geeignetes IDS oder IPS zum Schutz vor bekannten Angriffen und unbefugtem Zugriff auf alle seine Systeme zu implementieren, kontinuierlich zu überwachen und zu pflegen.</li>
    </ol>
    <p>„Systeme“ bezeichnet die Computersysteme (einschließlich Software, Hardware, Betriebssysteme, Interneteinrichtungen, IT-Netze und informationstechnische Infrastruktur) eines Nutzers. </p>
    <p>„Sicherheitspatches“ bezeichnet den Prozess der Aktualisierung der Sicherheitskonfiguration eines Systems zum Schutz vor dokumentierten Sicherheitsrisiken.</p>
    <p>„Signaturen“ sind der Fingerabdruck des Computercodes oder Updates, die auf Sicherheitssystemen und -anwendungen installiert werden, um vor Viren und anderen bekannten Angriffsvektoren zu schützen. </p>
    <p>„IDS“ bezeichnet ein Intrusion Detection System und „IPS“ ein Intrusion Prevention System. IDS und IPS sind Computersysteme, die den Netzwerkverkehr überwachen, um verdächtige Aktivitäten zu erkennen, die auf potenzielle Angriffe oder Versuche, sich unbefugten Zugang zu verschaffen, hinweisen. </p>
    <p>2. Elektronische Datenkommunikation und -übertragung zwischen dem Nutzer und den Softwaresystemen von 360X Art und den Nutzern der Systeme müssen durch geeignete Verschlüsselungstechnologien gegen Abhören geschützt werden.</p>
    <p>„Geeignete Verschlüsselungstechnologien“ sind Technologien, die die Implementierung einer starken Verschlüsselung ermöglichen. Einige Beispiele hierfür sind, aber nicht beschränkt auf: SSL-Zertifikate mit AES-256-Bit-Verschlüsselung, IPSEC VPN mit AES-256-Bit-Verschlüsselung, Festplattenverschlüsselungssoftware mit PGP-Verschlüsselung und WPA2 mit AES-256-Bit-Verschlüsselung. </p>
    <p>3. Der Nutzer wählt die in seinem Namen handelnden autorisierten Nutzer mit der gebotenen Sorgfalt aus.</p>
    <p>4. Der Nutzer hat die strikte Vertraulichkeit der Passwörter zu gewährleisten, indem er Sicherheitsmethoden für die Speicherung von und den Zugriff auf Passwörter und Anmeldedaten verwendet, die den höchsten Industriestandards entsprechen. Der Nutzer muss 360X Art unverzüglich über jeden Verlust oder vermuteten Missbrauch des Passworts informieren. </p>
    <p>5. Jedes Passwort muss die folgenden Mindestanforderungen erfüllen:</p>
    <ol type="a">
      <li>Mindestens acht Zeichen lang, mit Groß- und Kleinbuchstaben, Ziffern von 0-9 und nicht-alphabetischen Zeichen;</li>
      <li>Das Passwort sollte in der gesamten Passworthistorie eindeutig sein.</li>
      <li>Das maximale Alter des Passworts beträgt neunzig Tage.</li>
      <li>Es darf nicht leicht zu erraten sein.</li>
      <li>Weitere Anforderungen nach Maßgabe der technischen Vorgaben der Systeme.</li>
    </ol>
    <p>6. Der Nutzer muss jede Sitzung durch ordnungsgemäßes Ausloggen aus dem Nutzerkonto beenden.</p>
    <p>7. Der Nutzer muss:</p>
    <ol type="a">
      <li>Kontrollen durchführen und die Einhaltung der Sicherheitsanforderungen dieses ANB-Anhangs 2 fortlaufend überwachen; </li>
      <li>Sicherheitssysteme und -verfahren unterhalten, um zu verhindern und davor zu schützen, dass beide, sowohl Nutzer als auch 360X Art, gegen gesetzliche, straf- oder zivilrechtliche Verpflichtungen und Sicherheitsanforderungen, die sich aus der Nutzung des Dienstes ergeben, verstoßen.</li>
    </ol>
    <h2 id="ANB3" className="text-center">ANB ANHANG 3<br />
ZUSATZBEDINGUNGEN - PLATTFORM</h2>
    <h3>1.	ZUSATZBEDINGUNGEN ANWENDUNGSBEREICH</h3>
    <p>1.1	Diese Bedingungen stellen Zusatzbedingungen (die “Zusatzbedingungen“) zu den Allgemeinen Nutzungsbedingungen (einschließlich ihrer Anhänge) dar und gelten ergänzend zu den Allgemeinen Nutzungsbedingungen für den Zugang und die Nutzung der separat von der Website betriebene zugangsbeschränkte Plattform der 360X Art (die „Plattform“) sowie der Zusatzdienste, die nachfolgend in Ziffer 2 näher beschriebenen Vermittlungsdienste, die von 360X Art über die Plattform zugänglich sind. </p>
    <p>1.2	Die in den Allgemeinen Nutzungsbedingungen definierten Begriffe und Ausdrücke haben, sofern sich aus dem Zusammenhang nichts anderes ergibt, die gleiche Bedeutung in diesen Zusatzbedingungen. Wenn und soweit diese Zusatzbedingungen im Widerspruch zu den Allgemeinen Nutzungsbedingungen stehen, haben diese Zusatzbedingungen Vorrang.</p>
    <h3>2.	VERMITTLUNGSDIENSTE AUF DER PLATTFORM</h3>
    <p>2.1	Als Zusatzdienst erbringt 360X Art Finanzdienstleistung in Form der Anlagevermittlung für Emittenten von bestimmten Finanzinstrumenten („Emittenten“) an von 360X Art ausgewählte Nutzer („Investoren“). Diese Vermittlungsdienste erbringt 360X Art über die Plattform mit den im Annex (Dienstbeschreibung Zusatzdienst) zu diesen Zusatzbedingungen näher beschriebenen Funktionen.</p>
    <ol type="a">
      <li>Die Vermittlungsdienste erbringt 360X Art im eigenen Namen in offener Stellvertretung als vertraglich gebundener Vermittler der Effecta GmbH, Florstadt, Finanzinstrumente („Effecta“). 360X Art ist insoweit ein gebundener Vermittler im Sinne von § 3 Abs. 2 Wertpapierinstitutsgesetz WpIG.</li>
      <li>Zu diesem Zweck haben 360X Art und Effecta einen Vertrag geschlossen, wonach 360X Art bei der Vermittlung der Finanzinstrumente als Erfüllungsgehilfe der Effecta tätig wird. Hieraus haftet Effecta gegenüber den Investoren der Finanzinstrumente grundsätzlich für alle Schäden, die aufgrund einer Pflichtverletzung von 360X Art aus der Vermittlung von Finanzinstrumenten entstehen.</li>
      <li>360X Art ist als vertraglich gebundener Vermittler verpflichtet, Effecta jederzeit Einsicht in die Geschäftsunterlagen zu gewähren, soweit dies aufsichtsrechtlich geboten ist. Außerdem hat 360X Art als vertraglich gebundener Vermittler der Effecta zur Sicherung von Steuerungs- und Kontrollmöglichkeiten gemäß § 25a Absatz 2 Satz 1 KWG und § 80 WpHG ein umfassendes Weisungsrecht eingeräumt. 360X Art ist als vertraglich gebundener Vermittler ferner verpflichtet, Prüfungen und Begehungen der Geschäftsräume durch die Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin) zu dulden und an diesen Prüfungen mitzuwirken. Gleiches gilt für die interne Revision bzw. Compliance der Effecta.</li>
    </ol>
    <p>2.2	Im Rahmen der Vermittlungsdienste vermittelt 360X Art für Emittenten Finanzinstrumente an Investoren. Hierzu können Investoren auf der Plattform gegenüber der 360X Art Zeichnungsangebote für Finanzinstrumente abgeben. 360X Art wird diese Angebote an die Emittenten als Bote weiterleiten. Der Vertragsschluss erfolgt mit dem Zugang der jeweiligen Annahme durch den Emittenten beim Investor. Auch hier fungiert 360X Art als Bote. </p>
    <p>2.3	360X Art weist darauf hin, dass durch 360X Art lediglich die Angemessenheitsprüfung (§ 63 WpHG) vorgenommen wird, soweit diese für den jeweiligen Emittenten oder Investor gesetzlich zwingend vorgeschrieben ist. Hierbei werden die Kenntnisse und Erfahrungen des Investors in Bezug auf Geschäfte mit bestimmten Arten von Finanzinstrumenten abgefragt, um zu prüfen, ob der Investor die Risiken im Zusammenhang mit den Finanzinstrumenten angemessen beurteilen kann. Sollte 360X Art für eine solche Prüfung vom Investor nicht ausreichend Informationen erhalten, kann die Prüfung nicht durchgeführt werden. 360X Art wird den Investor hierauf hinweisen. Der Investor kann sich dennoch entscheiden, über die Plattform Finanzinstrumente zu erwerben. Gleiches gilt für Fälle, in denen 360X Art der Ansicht ist, der Investor kann die Risiken aus den Finanzinstrumenten nicht angemessen beurteilen. Auch hier wird 360X Art den Investor entsprechend informieren. Der Investor kann sich dennoch entscheiden, über die Plattform Finanzinstrumente zu erwerben. </p>
    <p>2.4	Investoren, die über 360X Art Finanzinstrumente erwerben wollen, müssen ihren Sitz bzw. Wohnsitz in Deutschland oder in einem anderen von 360X Art erlaubten Mitgliedstaat der Europäischen Union haben und dürfen den Vermittlungsdienst nur auf eigene Rechnung nutzen. </p>
    <p>2.5	Investoren werden vor Abschluss des Zeichnungsvertrags von 360X Art grundsätzlich als „Kleinanleger“/„Privatkunden“ klassifiziert (eingestuft). Dies gilt unabhängig von den Kenntnissen und Erfahrungen des Investors von und bei Geschäften mit Finanzinstrumenten und anderen Kapitalanlagen.</p>
    <p>Eine Einstufung als „Professioneller Kunde“ oder „Geeignete Gegenpartei“ ist durch eine schriftliche Vereinbarung zwischen Investor und 360X Art möglich, wenn und soweit der Investor dies beantragt und er gegenüber 360X Art die jeweiligen Voraussetzungen für eine Einstufung als „Professioneller Kunde“ oder „Geeignete Gegenpartei“ schriftlich nachweist. Eine Rückstufung auf „Privatkunde“ ist durch schriftliche Vereinbarung zwischen Investor und 360X Art möglich, soweit der Investor dies gegenüber 360X Art schriftlich verlangt.</p>
    <p>Die Klassifizierung als „Privatkunde“ führt dazu, dass der Investor das höchste gesetzliche Schutzniveau in Bezug auf Anlegerschutz und Transparenz im Rahmen der Geschäftsbeziehung genießt. Eine Umqualifizierung kann nachteilige Auswirkungen für den Investor in Bezug auf den Umfang der Prüfungspflichten von 360X Art gegenüber dem Investor vor Auftragsdurchführung haben.</p>
    <p>2.6	Im Falle des Erwerbs von Finanzinstrumenten erhält 360X Art von dem jeweiligen Emittenten eine Provision. Die genaue Höhe der Provision wird 360X Art dem Investor mitteilen, sobald diese feststeht. 360X Art nutzt diese Provisionen dazu, die Qualität der Dienstleistungen zu Gunsten der Investoren zu verbessern. Für Investoren ist die Vermittlung des Abschlusses der Finanzinstrumente durch 360X Art kostenfrei.</p>
    <p>2.7	Zahlung auf Finanzinstrumente haben unmittelbar an den jeweiligen Emittenten zu erfolgen. 360X Art nimmt selbst keine Zahlungen entgegen und/oder vor.</p>
    <h3>3.	REGISTRIERUNG UND NUTZERKONTO </h3>
    <p>3.1	Der Zugang zur Plattform ist nur für einen Investor möglich, der sich für den Zusatzdienst registriert hat, indem er ein Nutzerkonto (das „Nutzerkonto“) erstellt hat.</p>
    <p>3.2	Die Registrierung steht jeder (i) natürlichen Person, die das 18. Lebensjahr vollendet hat, (ii) juristischen Person und (iii) jedem anderem Unternehmen offen. Die Registrierung und Einrichtung des Nutzerkontos können durch eine namentlich benannte und bevollmächtigte natürliche Person erfolgen. Spätere Anmeldungen zum Nutzerkonto können automatisch erfolgen.</p>
    <p>3.3	Der Investor ist verpflichtet, alle im Rahmen der Registrierung abgefragten Daten vollständig und richtig anzugeben. Der Investor ist verpflichtet, 360X Art unverzüglich über etwaige Änderungen zu informieren, indem er diese in seinem Nutzerkonto eingibt bzw. aktualisiert. 360X Art kann nach eigenem Ermessen (angemessene) zusätzliche Informationen oder Unterlagen in Bezug auf einen Investor verlangen. 360X Art behält sich das Recht vor, die Anmeldung des Nutzers erst dann zu bearbeiten, wenn diese Informationen und/oder Unterlagen vollständig zur Verfügung gestellt worden sind.</p>
    <p>3.4	Alle Informationen, die einem Investor im Zusammenhang mit der Erstellung eines Nutzerkontos und/oder der Authentifizierung für den Zusatzdienst zur Verfügung gestellt werden (zusammen „Zugangsinformationen“), sind vertraulich und ausschließlich für die Nutzung durch den Investor bestimmt, dem diese Zugangsinformationen zugewiesen wurden. Sie dürfen ohne vorherige schriftliche Zustimmung von 360X Art nicht an Dritte weitergegeben werden und sind gegenüber Dritten vertraulich zu behandeln. </p>
    <p>3.5	Der Investor ist verpflichtet, seine Zugangsdaten vertraulich zu behandeln und den Zugang zu seinem Nutzerkonto mit der gebotenen Sorgfalt gemäß den Anforderungen der ANB Anlage 2 (IT-Sicherheitsanforderungen) zu sichern. Der Investor ist verpflichtet, 360X Art unverzüglich zu informieren, wenn er Grund zu der Annahme hat, dass ein unbefugter Dritter Zugang zu seinem Nutzerkonto hat. </p>
    <p>3.6	Alle Erklärungen, die über das Nutzerkonto abgegeben werden, werden dem Investor zugerechnet. 360X Art ist berechtigt, sich darauf zu verlassen, dass jede über das Nutzerkonto abgegebene Erklärung vom Investor stammt. Bei juristischen Personen, die keine natürlichen Personen sind, ist 360X Art berechtigt, die in einem Nutzerkonto identifizierte (natürliche) Person als von dem betreffenden Investor bevollmächtigt anzusehen, im Namen und im Auftrag des Investors in Bezug auf jede Handlung (einschließlich der Abgabe und des Empfangs von Willenserklärungen) im Rahmen des Zusatzdienstes zu handeln. Mit Ausnahme der Zugangsdaten ist 360X Art nicht verpflichtet, andere Berechtigungsnachweise zu überprüfen. </p>
    <p>3.7	360X Art behält sich das Recht vor, Nutzerkonten mit unvollständigen Registrierungen oder solchen, die falsche Angaben enthalten, nach angemessener Zeit zu löschen. Dies gilt auch für Nutzerkonten, die über einen längeren Zeitraum nicht genutzt wurden. Vor der Löschung eines solchen Nutzerkontos wird 360X Art den Nutzer mit einer angemessenen Frist vor der Löschung informieren. </p>
    <h3>4.	VERMITTLUNG EINES DIGITALEN SCHLIEßFACHS</h3>
    <p>4.1	Mit dem erstmaligen Erwerb eines Finanzinstrumentes in Form von token-basierten Wertpapieren ermöglicht 360X Art dem Investor ein digitales Schließfach in Form eines Non-Custodial Wallets zu eröffnen und zu nutzen. 360X Art stellt dem Investor die dafür erforderliche App zur Verfügung. Der Investor speichert und verwahrt seinen privaten kryptografischen Schlüssel selbst. 360X Art hat keinen Zugriff auf diesen. 360X Art weist darauf hin, dass 360X Art keine Dienstleistung im Hinblick auf die Verwahrung, Verwaltung und Sicherung von Token oder anderen Kryptowerten oder den kryptografischen Schlüsseln erbringt. </p>
    <p>4.2	Die Nutzung der App für die Non-Custodial Wallet ist für den Investor kostenfrei.</p>
    <p>4.3	Die App dient neben der Selbst-Verwahrung der Token der Zwei-Faktor-Authentisierung (2FA), mit der der Investor seine Transaktionen betätigen kann. Die Authentifizierungsanfrage (Direktfreigabe) wird dem Investor dabei direkt in der App zugestellt und Informationen zur Transaktion in der App angezeigt, damit der Investor überprüfen kann, ob er die Transaktion ausführen lassen will. Wenn der Investor die Transaktion freigibt, wird er aufgefordert, sich zu authentifizieren.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      