import 'swiper/css';
import "swiper/css/pagination";
import "./src/css/swiper-overrides.css";
import "./src/css/index.css";

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname, hash } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/data-privacy`, `/imprint`]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) !== -1 && !hash) {
    window.scrollTo(0, 0)
  }

  return false
}