exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-basisinformationsblaetter-mdx": () => import("./../../../src/pages/basisinformationsblaetter.mdx" /* webpackChunkName: "component---src-pages-basisinformationsblaetter-mdx" */),
  "component---src-pages-data-privacy-mdx": () => import("./../../../src/pages/data-privacy.mdx" /* webpackChunkName: "component---src-pages-data-privacy-mdx" */),
  "component---src-pages-imprint-mdx": () => import("./../../../src/pages/imprint.mdx" /* webpackChunkName: "component---src-pages-imprint-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-safekeeping-app-mdx": () => import("./../../../src/pages/safekeeping-app.mdx" /* webpackChunkName: "component---src-pages-safekeeping-app-mdx" */),
  "component---src-pages-terms-and-conditions-mdx": () => import("./../../../src/pages/terms-and-conditions.mdx" /* webpackChunkName: "component---src-pages-terms-and-conditions-mdx" */)
}

