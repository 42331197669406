import React from 'react'
import classnames from 'classnames'

export default function Container({className, ...props}) {

  const classNames = classnames("max-w-[1440px] px-5 md:px-8 mx-auto w-full", className);

  return (
    <div className={classNames}>
      {props.children}
    </div>
  )
}
