import React from 'react'
import PropTypes from 'prop-types'
import Headline from '../../components/Headline'
import Container from '../../components/Container'
import Grid from '../../components/Grid'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Button from '../../components/Button'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Logo from '../../images/logo.inline.svg';
import * as yup from "yup";

import * as contactStyles from './Contact.module.css';

const Contact = ({content, ...props}) => {

  const schema = yup.object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    message: yup.string(),
    accepts_terms: yup.bool().oneOf([true])
  }).required();

  const { register, handleSubmit, control, getValues, formState} = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const onSubmit = (data) => {    
    if(!formState.isSubmitting) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(data).toString(),
      })
        .then(() => true)
        .catch((error) => alert(error));
    }
  }

  return (
    <section id="contact" className="bg-purple py-section md:py-[160px]">
      <Container>
        <Grid>
          <div className='col-span-6'>
            <Headline as="h2" theme="dark">{content.headline}</Headline>
          </div>
          <div className='col-span-6'>
            <form onSubmit={handleSubmit(onSubmit)} name="contactForm" className='pt-4 relative' data-netlify="true">
              <input type="hidden" name="form-name" value="contactForm"  {...register('form-name')}/>

              {formState.isSubmitSuccessful && (
                <div className="absolute bg-red top-0 bottom-0 right-0 left-0 z-20 p-[50px]">
                  <span className="font-medium text-32">{content.thanksMessage}</span>
                  <div className='h-[30px] absolute bottom-[50px] left-[50px]'><Logo className="w-[211px]" /></div>
                </div>
              )}
              <div className='grid gap-5 md:gap-42 grid-cols-6 mb-3 md:mb-6'>
                <div className='col-span-6 sm:col-span-3'>
                <Controller
                    render={({ field: { onChange, value, name } } ) => <Input key={name} name={name} value={value} filled={value !== ''} onChange={onChange} label="First name"/>}
                    name="firstname"
                    control={control}
                    defaultValue=""
                  />
                </div>
                <div className='col-span-6 sm:col-span-3'>
                <Controller
                    render={({ field: { onChange, value, name } } ) => <Input key={name} name={name} value={value} filled={value !== ''} onChange={onChange} label="Last name"/>}
                    name="lastname"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className='grid gap-5 md:gap-42 grid-cols-6 mb-3 md:mb-6'>
                <div className='col-span-6 sm:col-span-3'>
                <Controller
                    render={({ field: { onChange, value, name } } ) => <Input key={name} type="email" name={name} value={value} filled={value !== ''} onChange={onChange} label="E-Mail"/>}
                    name="email"
                    control={control}
                    defaultValue=""
                  />
                </div>
              </div>
              <div className='grid gap-5 md:gap-42 grid-cols-6 mb-3 md:mb-6'>
                <div className='col-span-6'>
                  <Controller
                      render={({ field: { onChange, value, name } } ) => <Input key={name} type="textarea" name={name}  value={value} filled={value !== ''} onChange={onChange} label="Message"/>}
                      name="message"
                      control={control}
                      defaultValue=""
                    />
                </div>
              </div>
              <div>
                <div className="flex flex-col md:flex-row">
                  <div className="md:order-1 mb-6 md:mb-0">
                    <label className={contactStyles['checkbox']}>
                        <input type="checkbox" {...register('accepts_terms')}/>
                        <span className='text-red'>{content.acceptsTermsLabel}</span>
                    </label>
                  </div>
                  <div className="md:order-0 md:mr-[42px]">
                    <Button theme="dark" type="submit" disabled={!formState.isValid || formState.isSubmitted || formState.isSubmitting}>Send</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Container>
    </section>
  )
}


Contact.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string
  })
}

export default Contact;
