import React, {useEffect, useState} from 'react'
import classnames from 'classnames'

export default function Logo({...props}) {

  const [animate, setAnimate] = useState(props.animate);

  React.useEffect(() => {
    setAnimate(props.animate);
}, [props.animate])

  const logoWrapStyles = `
    h-[41px]
    transition-all
    truncate
    duration-300
    ${animate? `w-[45px]`: `w-[45px] md:w-[290px]`}
  `
  const svgStyles = `
    transition-all
    ${animate? `-translate-x-[116px]`: `-translate-x-[116px] md:-translate-x-0`}
    duration-300
  `

  const classNames = classnames(logoWrapStyles)


  return (
    <div className={classNames} onMouseEnter={() => setAnimate(false)} onMouseLeave={() => {
      setAnimate(true);
    }}>
      <svg width="289px" height="41px" viewBox="0 0 289 41" version="1.1" xmlns="http://www.w3.org/2000/svg" className={svgStyles}>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Group" fill="#490139">
                  <g id="art-wrap" transform="translate(165.771000, 1.127030)">
                      <path d="M12.571,30.59127 L31.739,30.59127 L35.663,39.81167 L44.31,39.81167 L27.016,0 L17.294,0 L2.84217094e-14,39.81167 L8.647,39.81167 L12.571,30.59127 Z M22.416,8.72861 L28.901,23.92177 L15.419,23.92177 L21.904,8.72861 L22.416,8.72861 Z M76.1,39.86267 L67.679,24.67977 L56.88,24.67977 L56.88,39.86267 L48.971,39.86267 L48.971,0.05106 L70.24,0.05106 C78.548,0.05106 83.497,4.88662 83.497,11.93507 C83.596,14.45247 82.848,16.93037 81.374,18.97307 C79.899,21.01587 77.783,22.50557 75.362,23.20447 L84.747,39.87297 L76.1,39.86267 Z M56.88,17.77467 L69.502,17.77467 C73.2,17.77467 75.362,15.43887 75.362,12.31417 C75.362,9.18947 73.262,6.91515 69.615,6.91515 L56.88,6.91515 L56.88,17.77467 Z M109.377,7.32491 L123.136,7.32491 L123.136,0.05106 L87.473,0.05106 L87.473,7.32491 L101.242,7.32491 L101.242,39.86267 L109.377,39.86267 L109.377,7.32491 Z" id="art"></path>
                  </g>
                  <g id="logo-wrap" transform="translate(116.668000, 1.178090)">
                      <path d="M9.959,0 L-2.84217094e-14,0 L-2.84217094e-14,9.95801 L9.958,9.95801 L9.958,29.85371 L-2.84217094e-14,29.85371 L-2.84217094e-14,39.81181 L9.959,39.81181 L9.959,29.85381 L29.864,29.85381 L29.864,9.94801 L9.959,9.94801 L9.959,0 Z M39.823,-8.8817842e-16 L29.865,-8.8817842e-16 L29.865,9.95801 L39.823,9.95801 L39.823,-8.8817842e-16 Z M39.823,29.85371 L29.865,29.85371 L29.865,39.81181 L39.823,39.81181 L39.823,29.85371 Z" id="logo"></path>
                  </g>
                  <g id="360-wrap">
                      <path d="M73.9887,20.4898 C73.9887,6.78212 80.986,0 90.5445,0 C100.103,0 106.936,6.77188 106.936,20.4898 C106.936,34.2077 100.164,40.9079 90.606,40.9079 C81.0475,40.9079 73.9887,34.2692 73.9887,20.4898 Z M98.5765,20.6025 C98.5765,10.9825 95.3903,6.78212 90.606,6.78212 C86.0572,6.78212 82.4101,10.8186 82.4101,20.541 C82.4101,30.2634 86.047,34.1975 90.606,34.1975 C95.165,34.1975 98.5663,30.038 98.5663,20.6025 L98.5765,20.6025 Z M32.6505,28.7887 C32.6505,35.8987 25.8171,40.9597 15.982,40.9597 C5.91128,40.9597 0.614694,35.8987 0,28.2252 L8.19592,28.2252 C8.82086,31.5753 10.9825,34.0751 16.0435,34.0751 C20.8279,34.0751 24.1165,31.8622 24.1165,28.3892 C24.1165,24.9162 21.1659,23.1028 17.1294,23.1028 L11.8943,23.1028 L11.8943,17.0173 L16.8938,17.0173 C19.9673,17.0173 23.0408,15.4191 23.0408,11.8949 C23.0408,8.596 20.2542,6.49584 16.1562,6.49584 C12.7447,6.49584 9.39457,8.14527 8.6467,11.5568 L0.450778,11.5568 C0.901554,4.44686 8.18564,0.0107988 16.5455,0.0107988 C24.4545,0.0107988 31.2776,4.04728 31.2776,11.3314 C31.2776,15.6547 28.7164,17.9803 26.1552,19.4044 L26.1552,19.8039 C28.0604,20.4034 29.7215,21.6014 30.8916,23.22 C32.0618,24.8387 32.6786,26.7916 32.6505,28.7887 Z M53.4075,40.9592 C62.9045,40.9592 69.3895,35.9597 69.3895,27.7638 C69.3895,19.9777 63.4679,14.9064 55.2208,14.9064 C53.653,14.9074 52.09,15.0791 50.5594,15.4186 L63.0787,0.573788 L53.2333,0.573788 L43.4494,12.9803 C40.6628,16.5046 37.2512,20.9919 37.2512,27.1388 C37.2512,35.4986 43.4494,40.9592 53.4075,40.9592 Z M53.5201,20.828 C58.6938,20.828 61.4804,23.7273 61.4804,27.5384 C61.4804,31.4007 58.6324,34.4742 53.4587,34.4742 C48.285,34.4742 45.4472,31.3495 45.4472,27.5384 C45.4472,23.7273 48.3465,20.828 53.5201,20.828 Z" id="360"></path>
                  </g>
              </g>
          </g>
      </svg>
    </div>
  )
}


