import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Legal/Legal.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>Die 360X Art AG („wir”, „uns”, „unsere/r/s”) verarbeitet personenbezogene Daten nur im Rahmen der gesetzlichen Bestimmungen, insbesondere der EU-Datenschutzgrundverordnung (EU) 2016/679 („DSGVO“) und des Bundesdatenschutzgesetzes („BDSG“). Dieser Datenschutzhinweis informiert Sie über die Verarbeitung der Sie betreffenden personenbezogenen Daten bei der Nutzung unserer Website und ihrer Funktionen unter https://www.360xart.com (unsere „Website“) sowie bei der Nutzung der separat von der Website durch die Cashlink Technologies GmbH („Cashlink“) betriebenen zugangsbeschränkten Plattform („Plattform“). Dieser Datenschutzhinweis enthält zudem Informationen darüber, welche Pflichten uns als vertraglich gebundener Vermittler treffen und wie wir Ihre personenbezogenen Daten zur Begründung und Erfüllung eines Vertragsverhältnisses mit Ihnen verarbeiten.</p>
    <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person („betroffene Person“) beziehen, wie z. B. die Besucher unserer Website, und können deren Namen, Kontaktdaten oder technische Kennungen einschließen.</p>
    <br />
    <h2>Inhaltsverzeichnis:</h2>
    <ol>
  <li><a href="#1">1.	Wer wir sind und wie Sie uns erreichen können </a>
    <ol>
      <li><a href="#1.1">1.1	Informationen zu uns als Verantwortlichem</a></li>
    </ol>
  </li>
  <li><a href="#2">2.	Wie wir persönliche Daten verarbeiten, wenn Sie diese Website besuchen</a>
    <ol>
      <li><a href="#2.1">2.1	Verarbeitung personenbezogener Daten durch unsere Webserver</a></li>
      <li><a href="#2.2">2.2	Nutzung von Netlify als unser Hosting-Dienstleister und Content Delivery Network</a></li>
    </ol>
  </li>
  <li><a href="#3">3.	Wie wir personenbezogene Daten verarbeiten, wenn Sie uns kontaktieren</a>
    <ol>
      <li><a href="#3.1">3.1	Verarbeitung personenbezogener Daten im Zusammenhang mit E-Mail-Korrespondenz</a></li>
      <li><a href="#3.2">3.2	Verarbeitung personenbezogener Daten im Zusammenhang mit Werbung</a></li>
    </ol>
  </li>
  <li><a href="#4">4.	Wie wir personenbezogene Daten verarbeiten, wenn Sie uns Ihre Bewerbung für ein Stellenangebot zusenden</a>
    <ol>
      <li><a href="#4.1">4.1	Nutzung von Personio für das Management von Stellenangeboten und Bewerbungen</a></li>
      <li><a href="#4.2">4.2	Müssen Sie Ihre personenbezogenen Daten an uns weitergeben?</a></li>
      <li><a href="#4.3">4.3	Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten während des Bewerbungsverfahrens</a></li>
      <li><a href="#4.4">4.4	Zeitraum für den wir Ihre personenbezogenen Daten aus dem Bewerbungsverfahren speichern</a></li>
    </ol>
  </li>
  <li><a href="#5">5.	Unsere Pflichten als vertraglich gebundener Vermittler</a></li>
  <li><a href="#6">6.	Wie wir personenbezogene Daten zur Begründung und Erfüllung eines Vertragsverhältnisses verarbeiten</a>
    <ol>
      <li><a href="#6.1">6.1	Erfassung vorvertraglicher personenbezogener Daten</a></li>
      <li><a href="#6.2">6.2	Erstellung eines Wallets</a>
        <ol>
          <li><a href="#6.2.1">6.2.1	Zwei-Faktor-Authentisierung</a></li>
          <li><a href="#6.2.2">6.2.2	Download der App</a></li>
          <li><a href="#6.2.3">6.2.3	Nutzung der App</a></li>
          <li><a href="#6.2.4">6.2.4	Push-Nachrichten</a></li>
        </ol>
      </li>
      <li><a href="#6.3">6.3	Abführung von Kirchensteuer</a></li>
      <li><a href="#6.4">6.4	Datenerhebung zur Erfüllung vorvertraglicher Pflichten</a></li>
      <li><a href="#6.5">6.5	Bereitstellung gemäß gesetzlicher Verpflichtungen</a></li>
      <li><a href="#6.6">6.6	Speicherdauer</a></li>
      <li><a href="#6.7">6.7	Nutzung von Cashlink Technologies</a></li>
      <li><a href="#6.8">6.8	Datenweitergabe an Zahlungsdienstleister</a></li>
    </ol>
  </li>
  <li><a href="#7">7.	Verwendung von Cookies auf unserer Website</a></li>
  <li><a href="#8">8.	Ihre Rechte als betroffene Person</a></li>
  <li><a href="#9">9.	Wir verzichten auf automatisierte individuelle Entscheidungsfindung</a></li>
  <li><a href="#10">10.	Allgemeine technische und organisatorische Maßnahmen</a></li>
  <li><a href="#11">11.	Änderungen dieses Datenschutzhinweises</a></li>
    </ol>
    <br />
    <h2 id="1">1.	Wer wir sind und wie Sie uns erreichen können </h2>
    <h3 id="1.1"> 1.1		Informationen zu uns als Verantwortlichem</h3>
    <p>360X Art AG<br />
Bockenheimer Anlage 46<br />
60322 Frankfurt am Main<br />
Deutschland<br /><br />
E-Mail: info@360xart.de<br />
    </p>
    <h2 id="2">2.	Wie wir persönliche Daten verarbeiten, wenn Sie diese Website besuchen</h2>
    <h3 id="2.1">2.1	Verarbeitung personenbezogener Daten durch unsere Webserver</h3>
    <p>Wenn Sie unsere Website besuchen, ohne andere personenbezogene Daten zu übermitteln, die technisch notwendig sind, um eine Verbindung zu unseren Webservern herzustellen und unsere Website anzuzeigen, sendet Ihr Browser automatisch bestimmte Informationen an unsere Webserver. Die folgenden Kategorien von personenbezogenen Daten werden automatisch erhoben:</p>
    <ul>
      <li>Öffentliche IP-Adresse des anfragenden Unternehmens oder Ihres Endgeräts;</li>
      <li>Datum und Uhrzeit der Anfrage, einschließlich Zeitzone;</li>
      <li>Angeforderte URL, einschließlich Abfrageparameter und Anfrage-Header;</li>
      <li>Zugriffsstatus/HTTP-Statuscode;</li>
      <li>Jeweils übertragene Datenmenge;</li>
      <li>Website, von der die Anfrage ausgeht (sog. Referrer URL);</li>
      <li>HTTP-Header (einschließlich Typ, Version und Sprache Ihres Browsers, Ihres Betriebssystems und Ihrer Schnittstelle, Name Ihres Internetdienstanbieters).</li>
    </ul>
    <p>Die Rechtsgrundlage, auf die wir uns bei der Verarbeitung Ihrer personenbezogenen Daten stützen, ist Art. 6 Abs. 1 Satz 1 lit. f DSGVO, die uns erlaubt, personenbezogene Daten zu verarbeiten, wenn dies für unsere berechtigten Interessen erforderlich ist. Insbesondere verarbeiten wir die oben genannten Kategorien personenbezogener Daten, um die Kontinuität unseres Geschäftsbetriebs zu gewährleisten, indem wir Ihnen einen unterbrechungsfreien Zugang zu unserer Website ermöglichen und die Integrität unserer IT-Systeme aufrechterhalten (z.B. zur Verhinderung von Serverüberlastungen durch Distributed-Denial-of-Service (DDoS)-Angriffe). Diese personenbezogenen Daten werden vorübergehend in Server-Protokolldateien gespeichert, solange dies zur Aufrechterhaltung und Verbesserung der Sicherheit und Stabilität unserer IT-Systeme über einen Zeitraum von 90 Tagen erforderlich ist.</p>
    <h3 id="2.2">2.2	Nutzung von Netlify als unser Hosting-Dienstleister und Content Delivery Network</h3>
    <p>Wir nutzen Hosting-Dienste von Netlify, Inc. 2343 3rd Street 296, San Francisco CA 94107, Vereinigte Staaten von Amerika ("Netlify"). Netlify verarbeitet personenbezogene Daten in unserem Namen und auf unsere Anweisung gemäß eines Auftragsverarbeitungsvertrages (Art. 28 DSGVO). Im Rahmen der Datenverarbeitung durch Netlify können personenbezogene Daten außerhalb der Europäischen Union oder des Europäischen Wirtschaftsraums, einschließlich der Vereinigten Staaten von Amerika, verarbeitet werden. In diesem Zusammenhang haben wir mit Netlify Standardvertragsklauseln (Modul 2: Übermittlung von Verantwortlichen an Auftragsverarbeiter) als angemessene Schutzmaßnahmen vereinbart. Eine Kopie der Standardvertragsklauseln finden Sie unter  <a href="https://www.netlify.com/v3/static/pdf/netlify-dpa.pdf" rel="noreferrer" target="_blank">https://www.netlify.com/v3/static/pdf/netlify-dpa.pdf</a>.</p>
    <h2 id="3">3.	Wie wir personenbezogene Daten verarbeiten, wenn Sie uns kontaktieren</h2>
    <h3 id="3.1">3.1	Verarbeitung personenbezogener Daten im Zusammenhang mit E-Mail-Korrespondenz</h3>
    <p>Wenn Sie uns per E-Mail oder über ein Kontaktformular kontaktieren, erheben, nutzen und speichern wir die von Ihnen angegebenen personenbezogenen Daten (wie z.B. Daten zur künstlerischen Arbeit und Tätigkeiten, Ihren Namen, Ihre E-Mail-Adresse, Ihre Kontaktdaten und weitere von Ihnen zur Verfügung gestellte Informationen), um Ihre Anfrage angemessen bearbeiten und beantworten zu können. Alle Daten, die Sie uns über ein Kontaktformular auf dieser Website zur Verfügung stellen, werden verschlüsselt zwischen Ihrem Browser und unseren Servern übertragen.</p>
    <p>Wir verarbeiten die personenbezogenen Daten, die Sie uns zur Verfügung stellen, um Ihre Anfrage zu bearbeiten oder wenn wir zur Erfüllung gesetzlicher Pflichten (z.B. nach geltendem Steuerrecht) zu bestimmten Verarbeitungsvorgängen verpflichtet sind. Im Einzelfall verarbeiten wir Ihre Daten zur Wahrung unserer Rechte (z. B. Übermittlung Ihrer Daten an Strafverfolgungsbehörden bei Verdacht auf Straftaten und Ordnungswidrigkeiten, wie z. B. Täuschung oder Fälschung).</p>
    <p>Wir löschen diese Daten, wenn die Speicherung den Umständen nach nicht mehr erforderlich ist (z.B. wenn wir Ihre Anfrage abschließend beantwortet haben). Alternativ schränken wir die Verarbeitung ein, wenn gesetzliche Aufbewahrungspflichten bestehen, insbesondere wenn wir nach deutschem Bundesrecht verpflichtet sind, unsere elektronische Korrespondenz mit Handelsbriefen und anderen geschäftlichen und/oder steuerlichen Unterlagen für einen Zeitraum von bis zu sechs oder zehn Jahren aufzubewahren (siehe § 147 Abgabenordnung, § 257 Handelsgesetzbuch).</p>
    <p>Die Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 Satz 1 lit. f DSGVO, es sei denn, Ihre Anfrage dient dazu, Maßnahmen vor Abschluss, Durchführung oder Beendigung eines Vertrags mit uns zu ergreifen. In diesem Fall ergibt sich die Rechtsgrundlage aus Art. 6 Abs. 1 Satz 1 lit. b DSGVO. Soweit wir Ihre Daten aufgrund einer rechtlichen Verpflichtung verarbeiten, ist die Rechtsgrundlage Art. 6 Abs. 1 Satz 1 lit. c DSGVO. Die Verarbeitung Ihrer Daten bei Verdacht auf Täuschungsversuch oder Fälschungsverdacht ist Art. 6 Abs. 1 Satz 1 lit. f DSGVO.</p>
    <h3 id="3.2">3.2	Verarbeitung personenbezogener Daten im Zusammenhang mit Werbung</h3>
    <p>Wir verwenden Ihren Namen, Ihre Anschrift, Telefonnummer oder Ihre E-Mail-Adresse, um Ihnen Werbung zu Finanzinstrumenten (Wertpapiere, Vermögensanlagen etc.) zukommen zu lassen, wenn Sie in deren postalischen, elektronischen oder telefonischen Erhalt eingewilligt haben. Rechtsgrundlage ist Art. 6 Abs. 1 Satz 1 lit. a DSGVO. Sie können Ihre Einwilligung jederzeit für die Zukunft widerrufen. </p>
    <p>Wir verwenden Ihren Namen und Ihre E-Mail-Adresse auch für Zwecke der E-Mail-Werbung für eigene ähnliche Waren oder Dienstleistungen, wenn Sie zuvor einen Vertrag mit uns abgeschlossen haben. Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 Satz 1 lit. f DSGVO, wobei unsere berechtigten Interessen in der Durchführung von Direktwerbung bestehen. Dieser Verarbeitung Ihrer personenbezogenen Daten für Zwecke der E-Mail-Werbung können Sie jederzeit widersprechen, z.B. in dem Sie uns eine E-Mail an support@360xart.de schicken. </p>
    <p>Ihre Daten werden von uns gespeichert, solange dies für die Übermittlung von Werbung erforderlich ist und Sie Ihre Einwilligung nicht widerrufen oder der Datenverarbeitung nicht widersprochen haben. Eine Speicherung der personenbezogenen Daten im Zusammenhang mit einer anderen in Datenschutzerklärung beschriebenen Verarbeitungssituation (z.B. bei der Vermittlung von Finanzinstrumenten) bleibt hiervon unberührt.</p>
    <h2 id="4">4.	Wie wir personenbezogene Daten verarbeiten, wenn Sie uns Ihre Bewerbung für ein Stellenangebot zusenden</h2>
    <h3 id="4.1">4.1	Nutzung von Personio für das Management von Stellenangeboten und Bewerbungen</h3>
    <p>Wir nutzen die Personalverwaltungs- und Bewerbermanagementsoftware der Personio GmbH, Rundfunkplatz 4, 80335 München, Deutschland („Personio“). Wenn Sie uns Ihre Bewerbung über das Personio-Bewerbungsformular übermitteln, werden die darin enthaltenen personenbezogenen Daten mittels TLS-Verschlüsselung an die Server von Personio in Frankfurt am Main übertragen und gespeichert. Auf diese Daten kann nur mit einem Entschlüsselungscode zugegriffen werden. Wir haben eine Auftragsverarbeitungsvertrag mit Personio abgeschlossen, die als Auftragsverarbeiter fungiert und personenbezogene Daten von Bewerbern in unserem Namen und auf unsere Anweisung hin verarbeitet. Personio bietet weitere Informationen über ihre Maßnahmen zur Einhaltung der DSGVO und ihre technischen und organisatorischen Maßnahmen unter <a href="https://www.personio.com/data-security/" rel="noreferrer" target="_blank">https://www.personio.com/data-security/</a>. Für die Verarbeitung personenbezogener Daten auf der Website von Personio verweisen wir auf deren Datenschutzerklärung, die unter <a href="https://www.personio.com/privacy-policy/" rel="noreferrer" target="_blank">https://www.personio.com/privacy-policy/</a> abrufbar ist.</p>
    <h3 id="4.2">4.2	Müssen Sie Ihre personenbezogenen Daten an uns weitergeben?</h3>
    <p>Um sich bei uns zu bewerben, müssen Sie uns die Daten zur Verfügung stellen, die wir benötigen, um Sie zu beurteilen und eventuell auszuwählen. Die jeweils erforderlichen Angaben sind der Stellenbeschreibung zu entnehmen oder werden im Bewerbungsformular ausdrücklich genannt. Dazu gehören personenbezogene Daten wie Ihr Name, Ihre Adresse, eine Kontaktmöglichkeit und der Nachweis der für eine Stelle bei uns erforderlichen Qualifikation. Auf Anfrage stellen wir Ihnen gerne die für eine bestimmte Stelle erforderlichen Informationen zur Verfügung.</p>
    <p>Sie müssen nicht das Personio-Bewerbungsformular verwenden und können uns Ihre Bewerbung stattdessen z. B. per Brief schicken. Bitte beachten Sie jedoch, dass wir trotz des weit verbreiteten Einsatzes von Transportverschlüsselung nicht für die Sicherheit der Übertragung von E-Mails garantieren können, die Sie uns zum Zwecke der Bewerbung schicken. Die Übermittlung unverschlüsselter E-Mails an uns erfolgt daher auf Ihr eigenes Risiko.</p>
    <h3 id="4.3">4.3	Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten während des Bewerbungsverfahrens</h3>
    <p>Die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten im Rahmen des Bewerbungsverfahrens ergibt sich aus Art. 6 Abs. 1 Satz 1 lit. b DSGVO in Verbindung mit § 26 Abs. 1 Satz 1 BDSG.</p>
    <p>Sofern wir besondere Kategorien personenbezogener Daten im Sinne von Art. 9 Abs. 1 DSGVO (z.B. Gesundheitsdaten, wie das Vorliegen einer Schwerbehinderung, oder ethnische Herkunft) von Bewerbern im Rahmen des Bewerbungsverfahrens erheben, um uns oder dem Bewerber die Ausübung der besonderen Rechte im Bereich des Arbeits- und Sozialrechts zu ermöglichen, werden diese Daten gemäß Art. 9 Abs. 2 lit. b DSGVO in Verbindung mit § 26 Abs. 3 Satz 1 BDSG verarbeitet. Soweit dies zum Schutz lebenswichtiger Interessen von Bewerbern oder anderen Personen erforderlich ist, dient Art. 9 Abs. 2 lit. c DSGVO als Rechtsgrundlage. Für Zwecke der Präventiv- oder Arbeitsmedizin, der Beurteilung der Arbeitsfähigkeit des Bewerbers, der medizinischen Diagnostik, der Bereitstellung von Gesundheits- oder Sozialfürsorge oder Behandlung oder der Verwaltung von Gesundheits- oder Sozialfürsorgesystemen und -diensten stützen wir uns auf Art. 9 Abs. 2 lit. h DSGVO in Verbindung mit anwendbaren Bestimmungen aus dem Recht der Europäischen Union oder dem deutschen Recht. Wenn wir Sie in diesem Zusammenhang um Ihre freiwillige und ausdrückliche Einwilligung bitten, verarbeiten wir besondere Kategorien personenbezogener Daten auf der Grundlage von Art. 9 Abs. 2 lit. a DSGVO in Verbindung mit § 26 Abs. 3 Satz 2 BDSG.</p>
    <h3 id="4.4">4.4	Zeitraum für den wir Ihre personenbezogenen Daten aus dem Bewerbungsverfahren speichern</h3>
    <p>Wenn Ihre Bewerbung erfolgreich ist, werden Ihre Bewerberdaten von uns zur Begründung, Durchführung und Beendigung des Arbeitsverhältnisses weiterverarbeitet.</p>
    <p>Andernfalls, wenn die Bewerbung um ein Stellenangebot nicht erfolgreich ist, werden die Daten der Bewerber gelöscht. Wir löschen personenbezogene Daten von Bewerbern auch, wenn sie ihre Bewerbung zurückziehen, wozu sie jederzeit berechtigt sind. Wir löschen personenbezogene Daten aus dem Bewerbungsverfahren spätestens sechs Monate nach Abschluss des Verfahrens. Die Speicherung ist erforderlich, um eventuelle Nachfragen zum Bewerbungsverfahren beantworten zu können und um die Anforderungen an die Gleichbehandlung von Bewerbern nach dem Allgemeinen Gleichbehandlungsgesetz und die geltenden Fristen zu erfüllen. Die Rechnungen über die Erstattung von Reisekosten werden entsprechend den steuerrechtlichen Anforderungen aufbewahrt (siehe Abschnitt 3.1 oben).</p>
    <h2 id="5">5.	Unsere Pflichten als vertraglich gebundener Vermittler</h2>
    <p>Wir sind als vertraglich gebundener Vermittler im Sinne von § 3 Abs. 2 des Gesetzes zur Beaufsichtigung von Wertpapierinstituten („WpIG“) bei der Vermittlung von Finanzinstrumenten gemäß § 2 Abs. 2 Nr. 3 WpIG ausschließlich auf Rechnung und unter Haftung der Effecta GmbH, Florstadt (nachfolgend auch die „Effecta“ genannt) tätig. Die 360X Art AG ist bei der BaFin als gebundener Vermittler gemeldet: BaFin-Register gebundener Vermittler (Registernummer: wird nachgereicht). Die für die Begründung und Erfüllung von Verträgen über Finanzinstrumente (Wertpapiere, Vermögensanlagen, etc.) erforderlichen personenbezogenen Daten geben wir an die Effecta, die Cashlink, den jeweiligen Emittenten und Käufern des Finanzinstruments, Vertragspartner, welche Informationen zu Kunstwerken oder Finanzinstrumenten erstellen, wie Gutachter, Analysten oder Versicherer sowie ggf. an Banken, depotführende Institute oder sonstige Verwahrstellen und/oder Treuhänder weiter. Zur Weitergabe sind wir gemäß Art. 6 Abs. 1 lit. b DSGVO im Rahmen der Begründung und Durchführung eines Vertragsverhältnisses berechtigt. Weitere Informationen zum Umgang mit Ihren personenbezogenen Daten finden Sie auf der Webseite der Effecta; <a href="https://www.effecta-gmbh.de/datenschutzerklaerung/" target="_blank" rel="noreferrer">https://www.effecta-gmbh.de/datenschutzerklaerung/</a>.</p>
    <h2 id="6">6.	Wie wir personenbezogene Daten zur Begründung und Erfüllung eines Vertragsverhältnisses verarbeiten</h2>
    <h3 id="6.1">6.1	Erfassung vorvertraglicher personenbezogener Daten</h3>
    <p>Wenn Sie ein Finanzinstrument (Wertpapier, Vermögensanlage, etc.) zeichnen, verarbeiten wir im vorvertraglichen Bereich und bei Vertragsschluss weitere personenbezogene Daten über Sie. Dies betrifft beispielsweise Ausweisdaten zur Identifizierung (Name, Vorname, Anschrift, Geburtsdatum und -ort, Staatsbürgerschaft, Ausweispapier, ausstellende Behörde, Ausstellungsort, Ausweisnummer, Gültigkeit), Name, Anschrift, E-Mail-Adresse, Telefonnummer, Bankverbindung, Steuerdaten, Wallet-Adresse(n) und Ihre bisherigen Kenntnisse und Erfahrungen im Kapitalmarktbereich. Weitere Daten stellen Sie uns ggf. über öffentliche und abgesicherte Verfahren wie POSTIDENT-Verfahren (POSTIDENT durch Videochat, POSTIDENT durch Online-Ausweisfunktion, POSTIDENT durch Postfiliale) der Deutschen Post AG, Bonn zur Verfügung. Die Deutschen Post AG wird Ihre Daten nur insoweit verarbeiten, wie dies zu Ihrer Identifizierung und zum Nachweis über die Durchführung der Identifizierung erforderlich ist und unsere Weisungen in Bezug auf Ihre Daten befolgen. Weitere Informationen zum Umgang mit Ihren Daten finden Sie auf der Webseite der Deutschen Post AG: <a href="https://www.deutschepost.de/content/dam/dpag/html/ext/postid/Datenschutzhinweise.pdf" target="_blank" rel="noreferrer">https://www.deutschepost.de/content/dam/dpag/html/ext/postid/Datenschutzhinweise.pdf</a>. </p>
    <h3 id="6.2">6.2	Erstellung eines Wallets </h3>
    <p>Mit der erstmaligen Zeichnung eines Finanzinstrumentes in Form von tokenbasierten Wertpapieren können Sie ein digitales Schließfach in Form eines Non-Custodial Wallets eröffnen und nutzen. Für die Erstellung der Wallet stellen wir Ihnen eine App zur Verfügung, die dazu benötigt wird. Um Ihnen die App zur Verfügung zu stellen, bedienen wir uns des externen Dienstleisters Cashlink, über den wir Sie in Ziffer 6.7 ausführlich informieren. Sie speichern und verwahren Ihren privaten kryptografischen Schlüssel („Private Key“) selbst. Wir haben keinen Zugriff auf diesen. Wir weisen darauf hin, dass wir keine Dienstleistung im Hinblick auf die Verwahrung, Verwaltung und Sicherung von Token oder anderen Kryptowerten oder den kryptografischen Schlüsseln erbringen. Zur Nutzung Ihres Wallets verarbeiten wir personenbezogene Daten in Form Ihrer Wallet-Adresse, die in der App generiert und dann an Cashlink weitergeleitet wird. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 lit. b DSGVO im Rahmen der Begründung und Durchführung des Vertragsverhältnisses mit Ihnen sowie Art. 6 Abs. 1 Satz 1 lit. f DSGVO, wobei unsere berechtigten Interessen an der Verfügbarkeit und Funktionsfähigkeit der App bestehen. </p>
    <h4 id="6.2.1">6.2.1	Zwei-Faktor-Authentisierung</h4>
    <p>Die App dient zudem als Zwei-Faktor-Authentisierung (2FA), mit der Sie Ihre Transaktionen bestätigen können. Die Authentifizierungsanfrage (Direktfreigabe) wird Ihnen dabei direkt in der App zugestellt und Informationen zur Transaktion in der App angezeigt, damit Sie überprüfen können, ob Sie die Transaktion ausführen lassen wollen. Wenn Sie die Transaktion freigeben, werden Sie aufgefordert, sich zu authentifizieren. Im Rahmen der Authentifizierung verarbeitet Cashlink personenbezogene Daten in Form Ihrer E-Mail-Adresse. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 lit. b DSGVO im Rahmen der Begründung und Durchführung des Vertragsverhältnisses mit Ihnen sowie Art. 6 Abs. 1 Satz 1 lit. f DSGVO, wobei unsere berechtigten Interessen an der Zurverfügungstellung einer 2FA-Lösung und damit verbunden der Absicherung der Transaktionen bestehen. </p>
    <h4 id="6.2.2">6.2.2	Download der App</h4>
    <p>Beim Herunterladen unserer mobilen App über einen App-Store werden die erforderlichen Informationen an diesen übertragen. Auf diese Datenerhebung haben wir keinen Einfluss und sind nicht dafür verantwortlich. Über die beim Download der App erhobenen personenbezogenen Daten und den Umgang mit Ihren Daten informieren Sie sich bitte direkt bei dem jeweiligen App-Store. Je nachdem, welchen App-Store Sie verwenden, finden Sie weitergehende Informationen hier:</p>
    <ul>
  <li>Google Play Store (bei Android Nutzern): Google Ireland Ltd., Gordon House, Barrow St, Dublin 4, Ireland, <a href="https://policies.google.com/privacy?hl=de&gl=de" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=de&gl=de</a></li>
  <li>Apple App Store (bei iOS Nutzern): Apple Distribution International Ltd., Hollyhill Industrial Estate, Cork, Ireland, <a href="https://www.apple.com/de/legal/privacy/data/de/app-store/" target="_blank" rel="noreferrer">https://www.apple.com/de/legal/privacy/data/de/app-store/</a></li>
    </ul>
    <h4 id="6.2.3">6.2.3	Nutzung der App</h4>
    <p>Bei der Ersteinrichtung der App melden Sie sich mit Ihren Anmeldedaten und Ihrem bei der Registrierung eines Nutzer-Accounts auf der Plattform selbst gewählten Passwort an. Diese Daten werden nur lokal in der App verwendet, damit Sie die App nutzen können. Dies dient ferner dazu, Ihre Daten vor dem unberechtigten Zugriff Dritter und vor Missbrauch zu schützen.</p>
    <p>Zur weiteren Nutzung der App können Sie sich neben den Anmeldedaten optional auch per Touch ID/Face ID (bei iOS Nutzern) oder Fingerprint (bei Android Nutzern) einloggen. Hierbei werden biometrische Daten, die besonders schützenswerte personenbezogener Daten darstellen, verarbeitet. Diese biometrischen Daten werden durch das Betriebssystem Ihres mobilen Endgeräts nur lokal gespeichert und nicht zur Datenverarbeitung an uns oder Dritte weitergegeben. An die App wird lediglich die Information gesendet, ob die Authentifizierung erfolgreich war. Daher findet durch uns in diesem Zusammenhang keine Verarbeitung personenbezogener Daten statt. Diese alternativen Anmeldeverfahren (ID/Face ID, Fingerprint) können Sie jederzeit über die Systemeinstellungen der App aktivieren und deaktivieren. Zum Umgang mit Ihren biometrischen Daten informieren Sie sich bitte anhand der Datenschutzerklärung des Anbieters des Betriebssystems Ihres mobilen Endgeräts.</p>
    <p>Bei Nutzung der App werden vom jeweiligen App-Store zudem Protokolldateien (IP-Adresse, Geräteaktivitäten) gehostet, um die Stabilität und Sicherheit unserer App zu gewährleisten. Die Datenverarbeitung erfolgt auf Grundlage von § 25 Abs. 2 Nr. 2 TTDSG sowie auf Grundlage unserer berechtigten Interessen gemäß Art. 6 Abs. 1 Satz 1 lit. f DSGVO an der Verfügbarkeit, Stabilität und Sicherheit unserer App sowie zur Erfüllung des mit Ihnen geschlossenen Vertrags über die Nutzung unserer App gemäß Art. 6 Abs. 1 Satz 1 lit. b DSGVO.</p>
    <h4 id="6.2.4">6.2.4	Push-Nachrichten</h4>
    <p>Auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DS-GVO) können wir Ihnen Push-Nachrichten an Ihr mobiles Endgerät senden. Push-Nachrichten sind Meldungen zur Erteilung von Hinweisen über unsere App, die an Ihr mobiles Endgerät gesendet werden, auch wenn Sie unsere App nicht aktiv nutzen. Zur Bereitstellung von Push-Nachrichten wird eine pseudonymisierte Push-ID von Ihrem mobilen Endgerät erstellt, die wir zum Versenden der Push-Nachrichten als Ziel nutzen. Dabei werden Informationen zum Betreff, Art der Push-Nachricht, Zeitpunkt des Versandes der Push-Nachricht verarbeitet sowie dazu ob und wann eine Push-Nachricht empfangen und gelesen wurde. Ihre Einwilligung zum Empfang von Push-Nachrichten erteilen Sie, indem Sie im Betriebssystem Ihres mobilen Endgerätes die Push-Funktion in den Einstellungen aktivieren. Die Zusendung von Push-Nachrichten können Sie jederzeit in den entsprechenden Einstellungen Ihres mobilen Endgeräts wieder deaktivieren.</p>
    <h4 id="6.3">6.3	Abführung von Kirchensteuer</h4>
    <p>Zum Zwecke der Abführung der Kirchensteuer auf Kapitalerträge (soweit eine Pflicht zur Abführung der Kirchensteuer durch 360X Art AG besteht), fragen wir beim Bundeszentralamt für Steuern die Kirchensteuermerkmale von Ihnen als registriertem Nutzer ab, damit ggf. die Kirchensteuer für Sie abgeführt werden kann. Einer Herausgabe Ihrer Daten können Sie durch die Erteilung eines Sperrvermerkes beim Bundeszentralamt für Steuern widersprechen. Ein Sperrvermerk bleibt bis zu einem schriftlichen Widerruf bestehen.</p>
    <h4 id="6.4">6.4	Datenerhebung zur Erfüllung vorvertraglicher Pflichten</h4>
    <p>Die oben genannten Daten erheben und verarbeiten wir zum Zweck der Vertragsdurchführung bzw. zur Erfüllung vorvertraglichen Pflichten. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO. Zudem verarbeiten wir diese Daten aufgrund gesetzlicher und aufsichtsbehördlicher Verpflichtungen für den Finanzsektor. Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.</p>
    <h4 id="6.5">6.5	Bereitstellung gemäß gesetzlicher Verpflichtungen</h4>
    <p>Die Bereitstellung der personenbezogenen Daten ist für den Vertragsabschluss und zur Erfüllung gesetzlicher Verpflichtungen, denen wir unterliegen, erforderlich. Sofern die personenbezogenen Daten nicht bereitgestellt werden, ist der Abschluss und die Durchführung des Zeichnungsvertrages nicht möglich.</p>
    <h4 id="6.6">6.6	Speicherdauer</h4>
    <p>Ihre Daten werden gelöscht, sobald sie für das Vertragsverhältnis nicht mehr erforderlich sind. Es können darüber hinaus gesetzliche Aufbewahrungspflichten bestehen, beispielsweise handels- oder steuerrechtliche Aufbewahrungspflichten. Sofern solche Pflichten zur Aufbewahrung bestehen, löschen wir Ihre Daten mit Ende dieser Aufbewahrungspflichten.</p>
    <h4 id="6.7">6.7	Nutzung von Cashlink Technologies</h4>
    <p>Für die Erhebung und Verarbeitung Ihrer personenbezogenen Daten im vorvertraglichen Bereich, bei Vertragsschluss, zur Vertragsdurchführung und zur Entwicklung und Betrieb der zur Verfügung gestellten App bedienen wir uns des externen Dienstleisters Cashlink. Um den Schutz Ihrer personenbezogenen Daten zu gewährleisten, haben wir einen Auftragsverarbeitungsvertrag mit Cashlink geschlossen, so dass Cashlink Ihre Daten nur aufgrund unserer Weisungen verarbeiten darf. Weitere Informationen über den Umgang mit Ihren personenbezogenen Daten durch Cashlink entnehmen Sie bitte der Datenschutzerklärungen direkt auf der Webseite dieses Anbieters: <a href="https://cashlink.de/privacy-policy/" target="_blank" ref="noreferrer">https://cashlink.de/privacy-policy/</a>.</p>
    <h4 id="6.8">6.8	Datenweitergabe an Zahlungsdienstleister</h4>
    <p>Darüber hinaus kann eine Datenweitergabe an Zahlungsdienstleister erfolgen, soweit diese zur Zahlungsabwicklung in den Online-Zeichnungsprozess der Plattform eingebunden sind. Die Datenverarbeitung erfolgt in diesem Fall auf Grundlage von Art. 6 Abs. 1 lit. b und c DSGVO sowie Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen an der Zahlungsabwicklung und Betrugsprävention.</p>
    <h2 id="7">7.	Verwendung von Cookies auf unserer Website</h2>
    <p>Wenn Sie unsere Website besuchen, ohne weitere personenbezogene Daten anzugeben oder auf bestimmte Funktionen unserer Website zuzugreifen, speichern wir die folgenden Cookies auf Ihrem Endgerät oder greifen darauf zu:</p>
    <ul>
      <li>Cookie-Name: @@scroll#</li>
      <li>Gastgeber: Netlify</li>
      <li>Zweck: Speichern der Bildlaufposition des Benutzers auf der Website</li>
      <li>Cookie-Typ: Notwendig</li>
      <li>Rechtsgrundlage: Art. 6 Abs. 1 Satz 1 lit. f DSGVO (berechtigte Interessen); § 25 Abs. 2 Nr. 2 TTDSG</li>
      <li>Aufbewahrungsfrist: Sitzung</li>
    </ul>
    <h2 id="8">8.	Ihre Rechte als betroffene Person</h2>
    <ul>
      <li>Als betroffene Person haben Sie das Recht, eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten von uns verarbeitet werden, und, wenn dies der Fall ist, das Recht auf Auskunft über die Sie betreffenden personenbezogenen Daten und auf eine Kopie dieser Daten (Art. 15 Abs. 1 und 3 DSGVO).</li>
      <li>Wenn wir unrichtige personenbezogene Daten verarbeiten, haben Sie das Recht auf Berichtigung (Art. 16 DSGVO).</li>
      <li>In einigen gesetzlich vorgesehenen Fällen können Sie die Löschung der Sie betreffenden personenbezogenen Daten oder die Einschränkung der Verarbeitung verlangen (Art. 17 und 18 DSGVO).</li>
      <li>Beruht die Verarbeitung auf Ihrer Einwilligung im Sinne von Art. 6(1), Satz 1, lit. a DSGVO und/oder Art. 9 Abs. 2 lit. a DSGVO beruht, können Sie Ihre Einwilligung jederzeit widerrufen (Art. 7 Abs. 3 DSGVO), ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Wir informieren Sie gesondert, wenn wir Ihre Einwilligung für die Verarbeitung Sie betreffender personenbezogener Daten für festgelegte, eindeutige und rechtmäßige Zwecke benötigen, die nicht durch diesen Datenschutzhinweis abgedeckt sind.</li>
      <li>Beruht die Verarbeitung auf Ihrer Einwilligung im Sinne von Art. 6 Abs. 1 Satz 1 lit. a DSGVO und/oder Art. 9 Abs. 2 lit. a DSGVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Satz 1 lit. b DSGVO und erfolgt mit automatisierten Mitteln, haben Sie das Recht, die Sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln (Art. 20 DSGVO).</li>
      <li><strong>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 Satz 1 lit. e oder f DSGVO zu widersprechen (Art. 21 Abs. 1 DSGVO). Sie können jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten auf der Grundlage von Art. 6 Abs. 1 Satz 1 lit. f DSGVO für Zwecke der Direktwerbung einlegen (Art. 21 Abs. 2 DSGVO), ohne dass es hierfür der Angabe von Gründen bedarf, die sich auf Ihre besondere Situation beziehen.</strong></li>
      <li>Außerdem haben Sie das Recht, eine Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde einzureichen. Sie können sich zum Beispiel an die Aufsichtsbehörde in dem EU-Mitgliedstaat wenden, in dem Sie Ihren gewöhnlichen Aufenthalt oder Ihren Arbeitsplatz haben oder an dem mutmaßlich der Verstoß stattgefunden hat. Die für uns zuständige Datenschutzaufsichtsbehörde ist der Hessische Beauftragte für Datenschutz und Informationsfreiheit, Postfach 3163, 65021 Wiesbaden, Deutschland, Telefon: +49 (0)611 1408-0, <a href="https://datenschutz.hessen.de" target="_blank" rel="noreferrer">https://datenschutz.hessen.de</a>.</li>
    </ul>
    <p>Wenn Sie Fragen oder Beschwerden darüber haben, wie wir Ihre personenbezogenen Daten verarbeiten, empfehlen wir Ihnen, sich zunächst mit uns in Verbindung zu setzen (siehe die Kontaktangaben unter Abschnitt 1.1 oben).</p>
    <h2 id="9">9.	Wir verzichten auf automatisierte individuelle Entscheidungsfindung</h2>
    <p>Wir treffen keine automatisierten Entscheidungen, die ausschließlich auf einer automatisierten Verarbeitung beruhen, einschließlich Profiling, die rechtliche Auswirkungen auf die Besucher unserer Website haben oder sie in ähnlicher Weise erheblich beeinträchtigen.</p>
    <h2 id="10">10.	Allgemeine technische und organisatorische Maßnahmen</h2>
    <p>Wir haben geeignete technische und organisatorische Maßnahmen ergriffen, um die von Ihnen zur Verfügung gestellten personenbezogenen Daten gegen unbeabsichtigte oder unrechtmäßige Vernichtung, Verlust, Veränderung, unbefugte Offenlegung bzw. unbefugtem Zugang zu schützen. So sind beispielsweise unsere Mitarbeiter und alle Personen, die unter unserer Aufsicht handeln, zur Einhaltung der Datenschutzgesetze und zur vertraulichen Behandlung personenbezogener Daten verpflichtet. Um die personenbezogenen Daten unserer Nutzer zu schützen, verwenden wir außerdem ein sicheres Online-Übertragungsprotokoll, die sogenannte „Transport Layer Security“ (TLS) Übertragung. Sie erkennen dies an einem angehängten „s“ an der URL („https://“) oder einem geschlossenen Schloss-Symbol in Ihrem Browser. Mit einem Klick auf das Symbol erhalten Sie weitere Informationen über das verwendete TLS-Zertifikat. Symbole und Erklärungen können je nach verwendetem Browser variieren. Die TLS-Verschlüsselung gewährleistet eine verschlüsselte und vollständige Übertragung Ihrer Daten.</p>
    <h2 id="11">11.	Änderungen dieses Datenschutzhinweises</h2>
    <p>Neue gesetzliche Anforderungen, Unternehmensentscheidungen oder technische Entwicklungen können zu Änderungen dieses Hinweises führen und von uns erfordern, diesen Datenschutzhinweis entsprechend anzupassen. Die jeweils aktuelle Version finden Sie auf unserer Website. Bitte beachten Sie, dass sich externe Links zu Websites Dritter oder deren Kontaktinformationen im Laufe der Zeit ändern können. Sollten Sie Informationen finden, die nicht mehr aktuell sind, teilen Sie uns dies bitte mit.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      