import React, {useEffect} from "react"
import Layout from '../layouts/Base';
import Seo from '../components/Seo';
import Headline from '../components/Headline';
import Container from '../components/Container';
import Copy from '../components/Copy';
import Button from '../components/Button';
import CtaBox from '../components/CtaBox';
import Grid from "../components/Grid/Grid";
import Image from "../components/Image";
import content from '../content/en.json';
import classnames from "classnames";
import Benefits from "../sections/Benefits";
import Contact from "../sections/Contact";
import HowItWorks from "../sections/HowItWorks";

const IndexPage = () => {

  useEffect(() => {
    window.scrollTo(0,1)
  }, [])

  return (
    <Layout>
      <Seo />
      <section className="bg-red min-h-screen ">
        <div className="pt-[240px] flex flex-col justify-end min-h-screen pb-20">
          <Container>
            <Headline as="h1" dangerouslySetInnerHTML={{ __html: content.hero.headline }} theme="light"/>
          </Container>
        </div>
      </section>
      <section id="about" className="bg-beige py-section md:py-[160px]">
        <Container>
          <div
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="600"
              data-sal-easing="ease"
              data-sal-easing="ease-out-back"
            >
            <Headline as="h2" theme="dark" align="center" className="mb-6 md:mb-12">{content.about.headline}</Headline>
            <Grid>
              <Copy className="md:text-center col-span-6 md:col-span-10 md:col-start-2">{content.about.copy}</Copy>
            </Grid>
          </div>
          {content.about.ctas && 
              <Grid className="pt-section">
              { content.about.ctas.map((cta, i) => {
                  return (
                    <div key={i}
                      className={classnames('col-span-5 md:col-span-6',{'col-start-2': i === 1}, {'md:pt-[120px]': i === 0})}>
                      <div                       
                        data-sal="slide-up"
                        data-sal-delay="200"
                        data-sal-duration="600"
                        data-sal-easing="ease-out-back">
                        <CtaBox headline={cta.headline} ctaText={cta.ctaText} ctaLink={cta.ctaLink} theme={cta.theme}></CtaBox>
                      </div>
                    </div>
                  )
                })
              }
            </Grid>
          }
        </Container>
      </section>
      <Benefits tabs={content.benefits.tabs} />
      <section id="partners" className="py-section bg-white md:py-[160px]">
          <Container>
            <div
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="600"
                data-sal-easing="ease"
                data-sal-easing="ease-out-back"
              >
              <Headline as="h2" align="center" className="mb-6 md:mb-12" dangerouslySetInnerHTML={{ __html: content.partners.headline }} theme="dark"/>
              <Grid>
                <Copy className="md:text-center col-span-6 md:col-span-10 md:col-start-2">{content.partners.copy}</Copy>
              </Grid>
            </div>
          </Container>
      </section>
      <HowItWorks headline={content["how-it-works"].headline} slides={content["how-it-works"].slides} />
      <section id="team" className="bg-beige py-section md:py-[160px]">
        <Container>
          <div
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-duration="600"
              data-sal-easing="ease"
              data-sal-easing="ease-out-back"
            >
            <Grid className="pb-[80px]">
              <div className="col-span-6">
                <Headline as="h2" theme={'dark'} className="leading-headline-copy">{content.team.headline}</Headline>
              </div>
              <div className="col-span-6">
                <Copy className="pt-6 md:pt-10 mb-[42px] md:mb-[58px]">
                  {content.team.copy}
                </Copy>
                <Button href={content.team.ctaLink}>{content.team.ctaText}</Button>
              </div>
            </Grid>
          </div>
        </Container>
        <Container>
          <Grid>
          { content.team.people.map((person, i) => {
              return (
                <div key={i} className="col-span-6 sm:col-span-3 md:col-span-3 mb-[80px] sm:mb-0">
                  <div
                    data-sal="slide-up"
                    data-sal-delay={i*200}
                    data-sal-duration="600"
                    data-sal-easing="ease"
                    data-sal-easing="ease-out-back"
                  >
                    <div className="mb-5 md:mb-12">
                      <Image filename={person.image} alt={person.name}/>
                    </div>
                    <div className="font-medium text-sm leading-6">
                      <h5 className="md:text-32">{person.name}</h5>
                      <p className="uppercase">{person.position}</p><br/>
                      <p className="uppercase">{content.team.favouriteLabel}</p>
                      <p dangerouslySetInnerHTML={{ __html: person.favourite}}></p>
                      <br className="hidden sm:block"/>
                      {person.linkedin && <a className="text-red text-xs md:text-[15px] uppercase" href={person.linkedin} rel="noreferrer" target="_blank">→ LinkedIn</a>}
                    </div>
                  </div>
                </div>
              )
            })
          }
          </Grid>
        </Container>
      </section>
      <Contact content={content.contact}/>
    </Layout>
  )
}

export default IndexPage
