import React from "react"
import Grid from "../../components/Grid";
import Container from "../../components/Container";
import Headline from "../../components/Headline";
import MainNav from '../../components/MainNav';
import FooterNav from '../../components/FooterNav';
import content from '../../content/en.json';

const Legal = (props) => {

  return (
    <div id="top">
      <MainNav logoOnly={true} items={content.menus.main.items}/>
      <main className="legal">
        <section className="bg-red ">
          <div className="pt-[160px] flex flex-col justify-end pb-[60px]">
            <Container>
              <Headline as="h1" theme="light" dangerouslySetInnerHTML={{__html: props.pageContext.frontmatter.title}} />
            </Container>
          </div>
        </section>
        <section className="py-section">
          <Container>
            {props.children}
          </Container>
        </section>
      </main>
      <footer className="h-screen bg-red pt-section pb-[50px] md:py-[80px] flex max-h-[768px] md:h-[540px]">
        <Container>
          <Grid className="place-content-stretch h-full">
            <div className="col-span-3">
              <FooterNav items={[{
                "label": "Home",
                "href": "/"
              }]}></FooterNav>
            </div>
            <div className="col-span-3">
              <FooterNav items={content.menus.footer.items}></FooterNav>
            </div>
            <div className="col-span-3 md:col-start-10 flex">
              <div>
              <div className="mt-auto md:mt-0 md:ml-auto font-body-extended uppercase leading-nav text-xs md:text-xl">
                {content.footer.copyright}
              </div>
              <div className="mt-4 text-xs">{content.footer.copyrightNote}</div>
              </div>
            </div>
          </Grid>
        </Container>
      </footer>
    </div>
  )
}

export default Legal;