import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'
import * as benefitStyles from './Benefits.module.css';
import Container from '../../components/Container';
import Headline from '../../components/Headline';
import Grid from '../../components/Grid';
import Copy from '../../components/Copy';
import Image from '../../components/Image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Pagination
} from 'swiper';
import Benefit from '../../components/Benefit';

// install Swiper modules
SwiperCore.use([Pagination]);

const Benefits = ({tabs,...props}) => {

  const [activeTab, setActiveTab] = useState('left');

  const changeTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  }
  const pagination = {
    "clickable": true,
    "renderBullet": function (index, className) {
      return '<span class="' + className + '"></span>';
    }
  }

  return (
    <section id="benefits">
      <div className ={classnames(benefitStyles['benefits'])}>
        <ul className="w-full h-[60px] md:h-20 justify-center grid grid-cols-2">
          <li className={classnames(benefitStyles['leftTab'], activeTab === 'left'? benefitStyles['active']: '')}><a className="w-full font-body-extended flex items-center justify-center text-lg md:text-xl" href="#" onClick={(e) => changeTab(e, 'left')}>Art Token Collectors</a></li>
          <li className={classnames(benefitStyles['rightTab'], activeTab === 'right'? benefitStyles['active']: '')}><a className="w-full font-body-extended flex items-center justify-center text-lg md:text-xl" href="#" onClick={(e) => changeTab(e, 'right')}>Art Owners</a></li>
        </ul>
        <div className={classnames("pt-10 pb-16 md:pt-[80px] md:pb-[160px]", {"bg-red": activeTab === 'left', "bg-purple text-red": activeTab === 'right'})}>
          <Container>
            <Grid className="pb-[80px]">
              <div className="col-span-6">
                {tabs[activeTab].headline && <Headline as="h2" theme={activeTab === 'left'? 'light': 'dark'} className="leading-headline-copy">{tabs[activeTab].headline}</Headline>}
              </div>
              <div className="col-span-6">
                <Copy className="pt-6 md:pt-10">
                  {tabs[activeTab].copy}
                </Copy>
              </div>
            </Grid>
            <div className={benefitStyles['benefits']}>
              <Swiper
                pagination={pagination}
                slidesPerView={1}  breakpoints={{
                  640: {
                    slidesPerView: 2,
                    centeredSlides: true,
                    spaceBetweenSlides: 42
                  },
                  1024: {
                    slidesPerView: 5,
                    centeredSlides: false,
                    spaceBetweenSlides: 42
                  }
                }}>
              {tabs[activeTab].benefits.map((el, i) => {
                  return (
                    <SwiperSlide key={`${i}_slide_${activeTab}`}>
                      <div key={`benefit_${i}_container_${activeTab}`} className={classnames('text-center px-10', {'md:px-[21px]': i > 0 && i < 4, 'md:pr-[21px]': i === 0, 'md:pl-[21px]': i === 4})}>
                        <Benefit key={`benefit_${i}_${activeTab}`} benefit={el}/>
                      </div>
                    </SwiperSlide>
                  )
                })
              }
              </Swiper>
            </div>
          </Container>
        </div>
      </div>
    </section>
  )
}

const tabContent = PropTypes.shape({
  headline: PropTypes.string,
  copy: PropTypes.string,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      copy: PropTypes.string,
      icon: PropTypes.string
    })
  )
})

Benefits.propTypes = {
  tabs: PropTypes.shape({
    left: PropTypes.shape(tabContent),
    right: PropTypes.shape(tabContent)
  })
}

Benefits.defaultProps = {
  "tabs": {
    "right": {},
    "left": {}
  }
}

export default Benefits;
